import './signup.css'
import { Link } from 'react-router-dom';
import {createRef, useState, useReducer} from 'react'
import axios from 'axios'
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';

function utilError(data){
  Swal.fire({
    title: "Success!",
    text: data,
    icon: "Success",
    confirmButtonText: "close",
  });
}

function SignUp(props){
  const navigate = useNavigate();
  const username = createRef()
  const email = createRef()
  const password = createRef()
  const address = createRef()
  const phone = createRef()
  const otpone = createRef()
  const otptwo = createRef()
  const otpthree = createRef()
  const otpfour = createRef()
  const sliderRem = createRef()
  const [mainScreen, setMainScreen] = useState(true)
  const [otpFromBackend, setOptFromBackend] = useState(0)
  const [datas, setdatas] = useState({})
  const [sliderValue, SetsliderValue] = useState('')


  function apperOTPScreen(){
    setMainScreen(mainScreen=> false)
  }

  function createAccount(el){
    el.preventDefault()
    if(username.current.value===''){
      props.loaderSet(false)
      utilError('please entre valid username')
      return
    }
    // if(email.current.value===''){
    //   props.loaderSet(false)
    //   utilError('please entre valid email address')
    //   return
    // }
    if(password.current.value===''){
      props.loaderSet(false)
      utilError('password must be more 8 charater long')
      return
    }
    if(password.current.value.split('').length<8){
      props.loaderSet(false)
      utilError('password must be more 8 charater long')
      return
    }
    if(address.current.value===''){
      props.loaderSet(false)
      utilError('please entre a valid address')
      return
    }
    if(phone.current.value===''){
      props.loaderSet(false)
      utilError('please entre a valid phone number')
      return
    }
    if(phone.current.value.split('').length<10){
      props.loaderSet(false)
      utilError('Phone number must be of 10 digit')
      return
    }
    // axios({
    //   method : 'GET',
    //   url : 'http://127.0.0.1:5000/code'
    // }).then(res=>{
    //   if(res.data.status==='success'){
    //     setOptFromBackend(otpFromBackend=> res.data.data.otp)
    //   }
    //   console.log(res)
    // })
    setOptFromBackend(otpFromBackend=> '4699')
    const obj = {
      username : username.current.value,
      email : '',
      password : password.current.value,
      address : address.current.value,
      phone : phone.current.value,
    }
    setdatas(datas=> obj)

    setMainScreen(mainScreen=> false)
    SetsliderValue(sliderValue=> '0')

  }

  function veriftyOTpandBUILD(el){
    el.preventDefault()
    props.loaderSet(true)
    const otp = otpone.current.value + otptwo.current.value + otpthree.current.value + otpfour.current.value 
      if(Number(otp)===Number(otpFromBackend)){
        axios({
          method : "POST",
          url : 'https://wheelb2cback.onrender.com/api/v1/user/open-acc',
          data : {
            username : datas.username,
            email : '',
            password : datas.password,
            address : datas.address,
            phone : datas.phone,
            key : Math.trunc(Math.random()*789789789)
          }
        }).then(res=>{
          if(res.data.status==='success'){
            props.loaderSet(false)
            Swal.fire({
              title: "Success!",
              text: res.data.data.message,
              icon: "Success",
              confirmButtonText: "close",
            });
            localStorage.setItem('b2ctoken', res.data.data.token)
            navigate('/')
          }
      })
    }else{
      Swal.fire({
        title: "Success!",
        text: 'Invalid OTP, please fill correct OTP',
        icon: "Success",
        confirmButtonText: "close",
      });
    }
  }

  function resendOTP(el){
    el.preventDefault()
    axios({
      method : "GET",
      url : "http://127.0.0.1:5000/code"
    }).then(res=>{
      if(res.data.status==='success'){
        console.log(res)
        Swal.fire({
          title: "Success!",
          text: "OTP sended successfully",
          icon: "Success",
          confirmButtonText: "close",
        });
      }
    })
  }


    return(
      <div className='signup flex flex-1'>
        {mainScreen ? 
        <form className='formSignup flex flex-dir gap16'>
          <h2 className='subHead'>Open Account</h2>
          <div className='cred flex flex-dir gap8'>
            <label className='label'>Username</label>
            <input ref={username} className='inp' placeholder='Username*' type='text'/>
          </div>
          {/* <div className='cred flex flex-dir gap8'>
            <label className='label'>Email</label>
            <input ref={email} className='inp' placeholder='Email*' type='text'/>
          </div> */}
          <div className='cred flex flex-dir gap8'>
            <label className='label'>Password</label>
            <input ref={password} className='inp' placeholder='Password*' type='Password'/>
          </div>
          <div className='cred flex flex-dir gap8'>
            <label className='label'>Shipping Address</label>
            <input ref={address} className='inp' placeholder='Shipping Address*' type='text'/>
          </div>
          <div className='cred flex flex-dir gap8'>
            <label className='label'>Phone</label>
            <input ref={phone} className='inp' placeholder='Phone*' type='number'/>
          </div>
          
          <button onClick={createAccount}  className='submit border_btn border_btn_fill'>Verify OTP</button>
          <Link to='/login' className='backToLogin'>Login</Link>
          <Link to='/' className="newAcc" >Home</Link>
        </form>
        :
          <div className='opt'>
            <form className='formSignup flex flex-dir gap16 pad16'>
              <h2 className='subHead'>Verifty Mobile</h2>
              <div className='optform flex flex-1 gap8'>
                <input ref={otpone} className='inp inp__updated inp__updated__' placeholder='0' type='text'/>
                <input ref={otptwo} className='inp inp__updated inp__updated__' placeholder='0' type='text'/>
                <input ref={otpthree} className='inp inp__updated inp__updated__' placeholder='0' type='text'/>
                <input ref={otpfour} className='inp inp__updated inp__updated__' placeholder='0' type='text'/>
              </div>
              <button onClick={resendOTP} className='resendOtp'>Resend OTP</button>
              <button onClick={veriftyOTpandBUILD} className='border_btn border_btn_fill'>Open Account</button>

            </form>
          </div>
        }
      </div>
    )
  }

  export default SignUp;