import './../util.css'
import './history.css'
import BottomMenu from './bottomMenu'
import {useEffect, useState} from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

function History(props){

    const [historuOrders, setHistoryOrders] = useState([])

    useEffect(()=>{
        props.loaderSet(true)
        axios({
            method : 'POST',
            url : 'https://wheelb2cback.onrender.com/api/v1/user/placed_orders',
            data : {
                token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout'
            }
        }).then(res=>{
            if(res.data.status==='success'){
                props.loaderSet(false)
                let ordersYetToDeliver = []
                res.data.data.placed_orders.forEach(el=>{
                    if(el.delivered==='delivered'){
                        ordersYetToDeliver.push(el)
                    }
                })
                setHistoryOrders(historuOrders=> ordersYetToDeliver)
                
            }
        })
    }, [])


    return(
        <div className='historyOrders'>
            <div className="place flex flex-dir">
            <div className='placedorders pad16 flex flex-dir gap16'>
                <p className='placedOrderNow'>Open orders</p>
                {/* {placedOrders.length===0 ? <img src={img} alt='img' className='noorderimg'/> : ''} */}
                {historuOrders.map(el=>
                    <div className='place placed pad16 flex flex-dir gap16'>
                        <div className='credentials flex flex-dir gap8'>
                            <p className='total'>Order id - {el._id}</p>
                            <div className='flex'>
                                <p className='total'>Order date - </p>
                                <p className='total'>{el.date}</p>
                            </div>
                            <div className='creds flex flex-2 gap8'>
                                <div className='flex flex-dir flex-1 gap8'>
                                    <p className='total'>Amount</p>
                                    <p className='total'>₹{el.total}/-</p>
                                </div>
                                <div className='flex flex-dir flex-1 gap8'>
                                    <p className='total'>Items</p>
                                    <p className='total'>{el.item_list.length}</p>
                                </div>
                                <div className='flex flex-dir flex-1 gap8'>
                                    <p className='total'>Payment status</p>
                                    <p className={el.payment_status==='pending' ? 'total red' : 'total green'}>{el.payment_status}</p>
                                </div>
                                <div className='flex flex-dir flex-1 gap8'>
                                    <p className='total'>Details</p>
                                    <Link to={`/detail/${el._id}`} className='border_btn border_btn_fill movetodetailbtn'>
                                        see detail
                                    </Link>
                                </div>
                            </div>
                            
                        </div> 
                    </div>
                )}
            </div>
            <div className='bottom'>
                <BottomMenu/>
            </div>
        </div>
        </div>
    )
}

export default History