import { Link } from "react-router-dom"
import './../util.css'
import './signup.css'
import './login.css'
import {createRef, useState} from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';

function utilError(data){
  Swal.fire({
    title: "Success!",
    text: data,
    icon: "Success",
    confirmButtonText: "close",
  });
}

function Login(props){

    const navigate = useNavigate();
    const phone = createRef()
    const password = createRef()

    function Login(el){
      props.loaderSet(true)
      el.preventDefault()
      if(phone.current.value===''){
        props.loaderSet(false)
        utilError('please entre valid email id')
        return;
      }
      if(password.current.value===''){
        props.loaderSet(false)
        utilError('please entre valid password')
        return;
      }
      axios({
        method : 'POST',
        url : 'https://wheelb2cback.onrender.com/api/v1/user/login',
        data : {
          phone : phone.current.value,
          password : password.current.value,
        }
      }).then(res=>{
        if(res.data.status==='success'){
          props.loaderSet(false)
          localStorage.setItem('b2ctoken', res.data.data.token)
          Swal.fire({
            title: "Success!",
            text: res.data.data.message,
            icon: "Success",
            confirmButtonText: "close",
          });
          navigate('/');
        }else{
          props.loaderSet(false)
          Swal.fire({
            title: "user not found",
            text: res.data.data.message,
            icon: "error",
            confirmButtonText: "close",
          });
        }
      })
    }

    return(
        <div className='signup flex flex-1'>
        <form className='formSignup flex flex-dir gap16'>
          <h2 className='subHead'>Login to your account</h2>
          <div className='cred flex flex-dir gap8'>
            <label className='label'>Phone</label>
            <input ref={phone} className='inp' placeholder='Phone*' type='number'/>
          </div>
          <div className='cred flex flex-dir gap8'>
            <label className='label'>Password</label>
            <input ref={password} className='inp' placeholder='Password*' type='Password'/>
          </div>
          <Link to='/forgot'>Forgot password</Link>
          <button onClick={Login} className='submit border_btn border_btn_fill'>Login</button>
          <Link to='/' className="newAcc" >Home</Link>

        </form>
      </div>
    )
}

export default Login;
