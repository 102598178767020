import './../util.css'
import './detail.css'
import BottomMenu from './bottomMenu'
import { useParams } from 'react-router'
import {useState, useEffect} from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router'

function Detail(){
    let { id } = useParams();
    const [details, setDetails] = useState([])
    const navigate = useNavigate()
    const [eligibleForCancellation, setEligibleForCancellation] = useState(false)

    useEffect(el=>{
        axios({
            method : 'POST',
            url : 'https://wheelb2cback.onrender.com/api/v1/user/details',
            data : {
                token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout',
                order_id : id
            }
        }).then(res=>{
            if(res.data.status==='success'){
                console.log(res.data.data.productDetail)
                setDetails(details=> res.data.data.productDetail)
                let dateOfOrder = res.data.data.productDetail[0].date.split('/')
                let placedOrderDateSum = 0
                let todayDateSum = 0

                dateOfOrder.forEach(el=>{
                    placedOrderDateSum += Number(el)
                })
                
                let todayDate = new Date().toLocaleDateString().split('/')
                todayDate.forEach(el=>{
                    todayDateSum += Number(el)
                })
                if(todayDateSum === placedOrderDateSum){
                    setEligibleForCancellation(eligibleForCancellation=> true)
                }else{
                    setEligibleForCancellation(eligibleForCancellation=> false)
                }

            }
        })
    }, [])

    function cancelOrder(el){
        el.preventDefault()
        axios({
            method : 'POST',
            url : 'https://wheelb2cback.onrender.com/api/v1/user/cancel-order',
            data : {
                token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout',
                order_id : details[0]._id
            }
        }).then(res=>{
            if(res.data.status==='success'){
                Swal.fire({
                    title: "Success!",
                    text: res.data.data.message,
                    icon: "Success",
                    confirmButtonText: "close",
                });
                navigate('/placed')
            }
        })
    }

    return(
        <div className="detial">
            <div className='detailbox pad16 flex flex-dir gap16'>
                <h2 className='detailhead'>Order details</h2>
                <div className='orderdetails'>
                    <div className='flex flex-dir gap16'>
                        <div className='creds flex flex-dir gap8'>
                            <p className='id'>order id - {id}</p>
                            <p className='id'>order date - {details[0]?.date}</p>
                            <p className='id'>Total items - {details[0]?.item_list.length}</p>
                        </div>
                        <div className='status'>
                    <div class="timeline">
                        {details[0]?.packed==='not packed' &&
                            <div class="container left">
                                <div class="content">
                                <h2>Not packed</h2>
                                </div>
                            </div>
                        }
                        {(details[0]?.packed==='packed' && details[0]?.delivered==='not delivered') &&
                            <>
                                <div class="container right">
                                    <div class="content">
                                    <h2>Packed</h2>
                                    </div>
                                </div>
                                <div class="container left">
                                    <div class="content">
                                    <h2>Ready for delivery</h2>
                                    </div>
                                </div>
                            </>
                        }

                        { details[0]?.outForDelivery==='out' &&
                            <div class="container right">
                                <div class="content">
                                <h2>Out for delivery</h2>
                                </div>
                            </div>
                        }

                        { details[0]?.delivered==='delivered' &&
                            <div class="container left">
                                <div class="content">
                                <h2>Delivered</h2>
                                </div>
                            </div>
                        }
                        
                        </div>

                    </div>
                        {details[0]?.item_list.map((el,index)=>
                            <div key={el} className='box flex flex-2 pad16 '>
                                <div className='flex gap8 flex-1'>
                                    <p className='name sno'>{index+1}.</p>
                                    <p className='name itemname'>{el.itemName}</p>
                                </div>
                                <div className='flex flex-dir gap8'>
                                    <p className='name'>Quantity - {el.units}kg</p>
                                    <p className='name'>₹{el.price}</p>
                                </div>
                            </div>
                        )}
                        <div className='creds grid grid-2-col gap8'>
                            <p className='id'>Subtotal - ₹{details[0]?.subtotal}/-</p>
                            <p className='id'>Delivery - ₹{details[0]?.delivery}/-</p>
                            <p className='id'>Tax - ₹{details[0]?.tax}/-</p>
                            <p className='id'>Platform - ₹{details[0]?.platform}/-</p>
                            <p className='id'>Total - ₹{details[0]?.total}/-</p>
                            <p className='id paymode'>Payment mode - {details[0]?.payment_mode}</p>
                            <p className='id paymode'>Payment status - {details[0]?.payment_status}</p>
                         </div>
                         
                        {(eligibleForCancellation && details[0]?.delivered==='not delivered') &&  <button onClick={cancelOrder} className='border_btn border_btn_fill'>Cancel order</button>}
                        
                    </div>
                    

                </div>
            </div>
            <BottomMenu/>
        </div>
    )
}

export default Detail