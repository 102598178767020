import "./../util.css";
import "./allProducts.css";
import Carrot from "./../icon/carrot.png";
import BottomMenu from "./bottomMenu";
import axios from "axios";
import Swal from "sweetalert2";
import { useState, useEffect, createRef } from "react";
import { Link } from "react-router-dom";
import noimg from './../icon/no-pictures.png'
import Dustbin from './../icon/delete.png'
import List from './../icon/list1.png'
import cross from './../icon/close.png'

// import watermelon from './../icon/watermelon.png'


function utilError(data){
  Swal.fire({
    title: "Success!",
    text: data,
    icon: "Success",
    confirmButtonText: "close",
  });
}

function AllProducts(props) {
  const [items, setItems] = useState([]);
  const filter_cat = createRef()
  const searchname = createRef()
  const [grossPrice, setgrossPrice] = useState('');
  const [coreCartList, setCoreCartList] = useState([])

  const [existingCart, setExistingCart]=useState([])
  const [existingCartWithQuantity, setExistingCartWithQuantity]=useState([])

  const [list, setList] = useState([])
  const [adrak] = useState("https://orderfreshlife.blob.core.windows.net/vegies/adrak.webp?sp=r&st=2024-03-16T22:46:24Z&se=2025-03-17T06:46:24Z&spr=https&sv=2022-11-02&sr=b&sig=YzmbtWwsoeMvZyKLZ%2BGJ73koqCKhrmLJAXUMHbku5Gg%3D")
  const [amla] = useState("https://orderfreshlife.blob.core.windows.net/vegies/amla.webp?sp=r&st=2024-03-16T22:47:30Z&se=2025-03-17T06:47:30Z&spr=https&sv=2022-11-02&sr=b&sig=Cgmmqo82qW57d8%2B%2F9xNLpSRqbrECMkN9m8hQ%2B0VHSRE%3D")
  const [apple] = useState("https://orderfreshlife.blob.core.windows.net/vegies/apple.webp?sp=r&st=2024-03-16T22:47:53Z&se=2025-03-17T06:47:53Z&spr=https&sv=2022-11-02&sr=b&sig=ZH%2FbQeh8xOFLG4ad2IHgruy%2F7fc7C%2Bw92L2u1qRw6mY%3D")
  const [arbi] = useState("https://orderfreshlife.blob.core.windows.net/vegies/arbi.webp?sp=r&st=2024-03-16T22:48:08Z&se=2025-03-17T06:48:08Z&spr=https&sv=2022-11-02&sr=b&sig=23uhTka8UIQPHCj3UvM3Alz%2B5wsGtlgHH8ZZTj7fsKY%3D")
  const [babbu] = useState("https://orderfreshlife.blob.core.windows.net/vegies/babbugosha.webp?sp=r&st=2024-03-16T22:48:36Z&se=2025-03-17T06:48:36Z&spr=https&sv=2022-11-02&sr=b&sig=ck3Q2RXOZYRTIXIzGSq2r%2F6J0QPxbFYnqoEwOhAVYkg%3D")
  const [corn] = useState("https://orderfreshlife.blob.core.windows.net/vegies/babycorn.webp?sp=r&st=2024-03-16T22:49:05Z&se=2025-03-17T06:49:05Z&spr=https&sv=2022-11-02&sr=b&sig=x%2FWL2UnUK80Sba6cg5%2B10mKmlCpOcbfaoF8qZ0HPQ9o%3D")
  const [baigan] = useState("https://orderfreshlife.blob.core.windows.net/vegies/baigan.webp?sp=r&st=2024-03-16T22:49:24Z&se=2025-03-17T06:49:24Z&spr=https&sv=2022-11-02&sr=b&sig=gfYCEP7AJZMCONBunUGvqC04GCyy%2Fw21ORT4b%2BqlYtA%3D")
  const [kela] = useState("https://orderfreshlife.blob.core.windows.net/vegies/banana.webp?sp=r&st=2024-03-16T22:49:42Z&se=2025-03-17T06:49:42Z&spr=https&sv=2022-11-02&sr=b&sig=AfemvMi79SLfyX0guKBix%2BhYROwzv7p0g%2B2LFAnIXmQ%3D")
  const [bandgobi] = useState("https://orderfreshlife.blob.core.windows.net/vegies/band%20ghobi.webp?sp=r&st=2024-03-16T22:49:59Z&se=2025-03-17T06:49:59Z&spr=https&sv=2022-11-02&sr=b&sig=P9R5cNawXO8LM%2B3uCXsBA9HZ%2FdVGXdZwMPQLolWSGmk%3D")
  const [basil] = useState("https://orderfreshlife.blob.core.windows.net/vegies/basil.webp?sp=r&st=2024-03-16T22:50:25Z&se=2025-03-17T06:50:25Z&spr=https&sv=2022-11-02&sr=b&sig=Mr0lX9dgjPBEiBlLmiOy28lOKvWfKSVxCJ4UYYKU%2F3w%3D")
  const [beans] = useState("https://orderfreshlife.blob.core.windows.net/vegies/beans.webp?sp=r&st=2024-03-16T22:50:42Z&se=2025-03-17T06:50:42Z&spr=https&sv=2022-11-02&sr=b&sig=9Vtrbxdz%2FpGbLLKQWb6cMEH4K0MwoJH3%2B2AbLw8oF4k%3D")
  const [beetroot] = useState("https://orderfreshlife.blob.core.windows.net/vegies/beans.webp?sp=r&st=2024-03-16T22:50:42Z&se=2025-03-17T06:50:42Z&spr=https&sv=2022-11-02&sr=b&sig=9Vtrbxdz%2FpGbLLKQWb6cMEH4K0MwoJH3%2B2AbLw8oF4k%3D")
  const [bhe] = useState("https://orderfreshlife.blob.core.windows.net/vegies/bhe.webp?sp=r&st=2024-03-16T22:51:23Z&se=2025-03-17T06:51:23Z&spr=https&sv=2022-11-02&sr=b&sig=7bNIv5AGSUWEQWTFLqjpmhum%2F8Y0z1YOkpFzRBF%2F%2BjI%3D")
  const [bhindi] = useState("https://orderfreshlife.blob.core.windows.net/vegies/bhindi.webp?sp=r&st=2024-03-16T22:51:39Z&se=2025-03-17T06:51:39Z&spr=https&sv=2022-11-02&sr=b&sig=SZAHXYzunnyfdFEO5iD27iOuZBPBYF95tQxEwPrqGBA%3D")
  const [bluegrape] = useState("https://orderfreshlife.blob.core.windows.net/vegies/blue%20graphes.webp?sp=r&st=2024-03-16T22:51:50Z&se=2025-03-17T06:51:50Z&spr=https&sv=2022-11-02&sr=b&sig=V75eWh9QoOp7EWUaER9495hFqLnIJWf6FO2X9aYuE0w%3D")
  const [broccli] = useState("https://orderfreshlife.blob.core.windows.net/vegies/broccoli-florets.jpg.webp?sp=r&st=2024-03-16T22:52:02Z&se=2025-03-17T06:52:02Z&spr=https&sv=2022-11-02&sr=b&sig=FcCL0KnxL6uwJEH4a7qiHsX4XW7bFi64eC2nrNR5Wuw%3D")
  const [cocbrown] = useState("https://orderfreshlife.blob.core.windows.net/vegies/brown%20coconut.webp?sp=r&st=2024-03-16T22:52:17Z&se=2025-03-17T06:52:17Z&spr=https&sv=2022-11-02&sr=b&sig=vRw0Lmk5dx9VcXIZFthsW%2FMzD951pSGmeSBjBUL9cBM%3D")
  const [carrot] = useState("https://orderfreshlife.blob.core.windows.net/vegies/carrot.webp?sp=r&st=2024-03-16T22:52:33Z&se=2025-03-17T06:52:33Z&spr=https&sv=2022-11-02&sr=b&sig=b4KFdKuP4oDWk1zfsZ1YUnsyMpwLI9rSOxiFsI8fSu0%3D")
  const [celery] = useState("https://orderfreshlife.blob.core.windows.net/vegies/celery.webp?sp=r&st=2024-03-16T22:52:49Z&se=2025-03-17T06:52:49Z&spr=https&sv=2022-11-02&sr=b&sig=0fm%2BDBh3HYSaOZZ4yS%2BsqR%2BuqNzPSQAAMOxCvK5qkUI%3D")
  const [cherryTomato] = useState("https://orderfreshlife.blob.core.windows.net/vegies/cherrytomato.webp?sp=r&st=2024-03-16T22:53:03Z&se=2025-03-17T06:53:03Z&spr=https&sv=2022-11-02&sr=b&sig=toqJUNkpCu8BFJ9yf96RTdvLwnCpW9V1LMaaL9xTEvQ%3D")
  const [chikko] = useState("https://orderfreshlife.blob.core.windows.net/vegies/chiku.webp?sp=r&st=2024-03-16T22:53:26Z&se=2025-03-17T06:53:26Z&spr=https&sv=2022-11-02&sr=b&sig=7ZUPyA8mr2UZKvE%2Ftg2Ed14l%2Bq6ETTWc2rhzD9kCKxI%3D")
  const [chinaCabbage] = useState("https://orderfreshlife.blob.core.windows.net/vegies/china%20cabbage.webp?sp=r&st=2024-03-16T22:53:42Z&se=2025-03-17T06:53:42Z&spr=https&sv=2022-11-02&sr=b&sig=ii3kZbuT%2FBysC5Unn2DZEtL2gnLHVxrAD1zWacClqCo%3D")
  const [dhaina] = useState("https://orderfreshlife.blob.core.windows.net/vegies/dhania.webp?sp=r&st=2024-03-16T22:54:20Z&se=2025-03-17T06:54:20Z&spr=https&sv=2022-11-02&sr=b&sig=FA7CNRIp5R350mckZm414cjz%2FhU8Qqm91WtvDGt1A8I%3D")
  const [dragon] = useState("https://orderfreshlife.blob.core.windows.net/vegies/Dragonfruit-White.jpg.webp?sp=r&st=2024-03-16T22:54:33Z&se=2025-03-17T06:54:33Z&spr=https&sv=2022-11-02&sr=b&sig=prQkf6R7erA1gzUv8i97afteDBfPwAhTvq8s0ct%2B2Pc%3D")
  const [fries] = useState("https://orderfreshlife.blob.core.windows.net/vegies/frozen%20french%20fries.jpeg.webp?sp=r&st=2024-03-16T22:55:26Z&se=2025-03-17T06:55:26Z&spr=https&sv=2022-11-02&sr=b&sig=nc%2BD%2FWwWcbOKNPFJ65GVWFeoNRsylVtGYJf9gPnaNgg%3D")
  const [noodles] = useState("https://orderfreshlife.blob.core.windows.net/vegies/frozen%20noodles.webp?sp=r&st=2024-03-16T22:55:39Z&se=2025-03-17T06:55:39Z&spr=https&sv=2022-11-02&sr=b&sig=WWo9Je4ZlSKacPIpJYK%2FIzRXwVD18PlaosF948hlijM%3D")
  const [kiwi] = useState("https://orderfreshlife.blob.core.windows.net/vegies/Kiwi-fruit.jpg.webp?sp=r&st=2024-03-16T22:56:03Z&se=2025-03-17T06:56:03Z&spr=https&sv=2022-11-02&sr=b&sig=eGDkTR2A%2B3%2Baq7Rq17Ue2IMlziEbnTSKwRdkUGNJ%2Fzw%3D")
  const [raniPine] = useState("https://orderfreshlife.blob.core.windows.net/vegies/Rani%20Pineapple_600x%402x.jpg.webp?sp=r&st=2024-03-16T22:56:15Z&se=2025-03-17T06:56:15Z&spr=https&sv=2022-11-02&sr=b&sig=0jMKT%2FBvBAOUv82qOAtmvwUK%2FhpeD2uw4bX14SDu2T0%3D")
  const [redcap] = useState("https://orderfreshlife.blob.core.windows.net/vegies/Red_Capsicum.jpg.webp?sp=r&st=2024-03-16T22:56:33Z&se=2025-03-17T06:56:33Z&spr=https&sv=2022-11-02&sr=b&sig=qAxYSz%2BvbRMngm%2FKgDz8x1O0AguHZA4XrtGD3vFmf3k%3D")
  const [frozenMattar] = useState("https://orderfreshlife.blob.core.windows.net/vegies/frozern%20mattar.webp?sp=r&st=2024-03-16T22:56:51Z&se=2025-03-17T06:56:51Z&spr=https&sv=2022-11-02&sr=b&sig=s65%2BMV7ZnIkvhfybt274C0Im7PmZss24aMH0UMEfN2Y%3D")
  const [fulgobi] = useState("https://orderfreshlife.blob.core.windows.net/vegies/fulgobhi.webp?sp=r&st=2024-03-16T22:57:04Z&se=2025-03-17T06:57:04Z&spr=https&sv=2022-11-02&sr=b&sig=QNnJS7%2BXate9VFezCLHycaTJdJ7J1RzNGuvNjN%2FUN2U%3D")
  const [longkaddu] = useState("https://orderfreshlife.blob.core.windows.net/vegies/ghiya.webp?sp=r&st=2024-03-16T22:57:38Z&se=2025-03-17T06:57:38Z&spr=https&sv=2022-11-02&sr=b&sig=GLlanPTpnMnAGRffDEFODvr2CVIM28tORxqc%2FDqVpyY%3D")
  const [golkaddu] = useState("https://orderfreshlife.blob.core.windows.net/vegies/gol%20kaddu.webp?sp=r&st=2024-03-16T22:57:52Z&se=2025-03-17T06:57:52Z&spr=https&sv=2022-11-02&sr=b&sig=uq2oc8nU6Jdo2AnQaVi9p7O8N56wsFQbCwiOpu%2BLeM4%3D")
  const [greenApple] = useState("https://orderfreshlife.blob.core.windows.net/vegies/green%20apple.jpg.webp?sp=r&st=2024-03-16T22:58:45Z&se=2025-03-17T06:58:45Z&spr=https&sv=2022-11-02&sr=b&sig=NFKcnr3Tj8m6UCjyP%2FSDUb5H6PBJjid2F5HlKOSFUTU%3D")
  const [greencoco] = useState("https://orderfreshlife.blob.core.windows.net/vegies/green%20cocnut.webp?sp=r&st=2024-03-16T22:58:57Z&se=2025-03-17T06:58:57Z&spr=https&sv=2022-11-02&sr=b&sig=n3qBbYh1dbe8Sp1GVYGkh47GGcGC7TTHtqo2X6B9XjQ%3D")
  const [grapes] = useState("https://orderfreshlife.blob.core.windows.net/vegies/green%20graphes.webp?sp=r&st=2024-03-16T22:59:09Z&se=2025-03-17T06:59:09Z&spr=https&sv=2022-11-02&sr=b&sig=FYqN8KmJSCS0%2FVhecKYEuCqagzDfbDU%2BSaEedMC3PcA%3D")
  const [greenOnion] = useState("https://orderfreshlife.blob.core.windows.net/vegies/green%20onion.webp?sp=r&st=2024-03-16T23:00:12Z&se=2025-03-17T07:00:12Z&spr=https&sv=2022-11-02&sr=b&sig=AfL%2FE%2B4lkJJ2Q24ClVG6BH6aHdD8b3LYyqd2LNqvViQ%3D")
  const [amrood] = useState("https://orderfreshlife.blob.core.windows.net/vegies/guava.webp?sp=r&st=2024-03-16T23:00:27Z&se=2025-03-17T07:00:27Z&spr=https&sv=2022-11-02&sr=b&sig=Q1FpJmpn0KWq%2Fe9x1pCfSVDIQ45QWYW4p9ql1JSgJaQ%3D")
  const [greenChilli] = useState("https://orderfreshlife.blob.core.windows.net/vegies/guava.webp?sp=r&st=2024-03-16T23:00:27Z&se=2025-03-17T07:00:27Z&spr=https&sv=2022-11-02&sr=b&sig=Q1FpJmpn0KWq%2Fe9x1pCfSVDIQ45QWYW4p9ql1JSgJaQ%3D")
  const [iceberg] = useState("https://orderfreshlife.blob.core.windows.net/vegies/iceberg.webp?sp=r&st=2024-03-16T23:02:04Z&se=2025-03-17T07:02:04Z&spr=https&sv=2022-11-02&sr=b&sig=TheyqzLVUFBwpSowsQSHa2eeDmi0GYXJZ9rnVUc2gZo%3D")
  const [jack] = useState("https://orderfreshlife.blob.core.windows.net/vegies/jackfruit.webp?sp=r&st=2024-03-16T23:02:21Z&se=2025-03-17T07:02:21Z&spr=https&sv=2022-11-02&sr=b&sig=JyHURH524FMOJEQJi%2F9c4xJYjB1DOEafbwUShdbtJCE%3D")
  const [jimi] = useState("https://orderfreshlife.blob.core.windows.net/vegies/jimikand.webp?sp=r&st=2024-03-16T23:02:35Z&se=2025-03-17T07:02:35Z&spr=https&sv=2022-11-02&sr=b&sig=vVz7UwGCZByFGBpQWaBYd%2F%2Ftym8XDg%2FzYCZc%2BwyqJvs%3D")
  const [karela] = useState("https://orderfreshlife.blob.core.windows.net/vegies/karela.webp?sp=r&st=2024-03-16T23:02:49Z&se=2025-03-17T07:02:49Z&spr=https&sv=2022-11-02&sr=b&sig=DOTlKHlHsr%2BcettOX6jdYPiKT6HBT6MG%2Bl8x42kmIdg%3D")
  const [khajoor] = useState("https://orderfreshlife.blob.core.windows.net/vegies/khajoor.jpg.webp?sp=r&st=2024-03-16T23:03:05Z&se=2025-03-17T07:03:05Z&spr=https&sv=2022-11-02&sr=b&sig=L5S1wxbcugPk4K1J76vwuGz2ecDKFeo6Wd%2BDo4BgoRM%3D")
  const [khera] = useState("https://orderfreshlife.blob.core.windows.net/vegies/khira.webp?sp=r&st=2024-03-16T23:03:24Z&se=2025-03-17T07:03:24Z&spr=https&sv=2022-11-02&sr=b&sig=nA23O0MFSwYd%2FgDFUg7Wvdt8xPMRyQGtzZihvxeZNzg%3D")
  const [kinnu] = useState("https://orderfreshlife.blob.core.windows.net/vegies/kinu.webp?sp=r&st=2024-03-16T23:03:37Z&se=2025-03-17T07:03:37Z&spr=https&sv=2022-11-02&sr=b&sig=rox0a%2F8ofvHcL6t3eBoOO5ccRV2E%2BCoD5KHbb0d2h4Q%3D")
  const [lasan] = useState("https://orderfreshlife.blob.core.windows.net/vegies/lassan.webp?sp=r&st=2024-03-16T23:03:52Z&se=2025-03-17T07:03:52Z&spr=https&sv=2022-11-02&sr=b&sig=9wweI9c8RNilcMXIdxssupbLvRW7K%2FZT2AT5ddbFaRk%3D")
  const [lichi] = useState("https://orderfreshlife.blob.core.windows.net/vegies/lichi.jpg.webp?sp=r&st=2024-03-16T23:04:21Z&se=2025-03-17T07:04:21Z&spr=https&sv=2022-11-02&sr=b&sig=iLJSPzjPK0o%2BmmXNYoVlvO%2FKxBCgudt6rPj%2BMA0p3R4%3D")
  const [mango] = useState("https://orderfreshlife.blob.core.windows.net/vegies/mango.webp?sp=r&st=2024-03-16T23:04:35Z&se=2025-03-17T07:04:35Z&spr=https&sv=2022-11-02&sr=b&sig=zz8eYIT1VPOniXNCP4y2ggd4nfkZ4YmHRiVSSzns9fk%3D")
  const [mattar] = useState("https://orderfreshlife.blob.core.windows.net/vegies/mattar.webp?sp=r&st=2024-03-16T23:05:05Z&se=2025-03-17T07:05:05Z&spr=https&sv=2022-11-02&sr=b&sig=6e8q7luaaIlmdrgRnIkNvRzOw%2BNl0WyXiyY9J4TsEDE%3D")
  const [mint] = useState("https://orderfreshlife.blob.core.windows.net/vegies/mint.webp?sp=r&st=2024-03-16T23:05:25Z&se=2025-03-17T07:05:25Z&spr=https&sv=2022-11-02&sr=b&sig=H3o3WRLXUABwamyPmHOY8nPma0O%2B7FHwleiU7X6P5Bg%3D")
  const [mooli] = useState("https://orderfreshlife.blob.core.windows.net/vegies/mooli.webp?sp=r&st=2024-03-16T23:05:42Z&se=2025-03-17T07:05:42Z&spr=https&sv=2022-11-02&sr=b&sig=NiNONcjckAuG2Nm%2BasBMQqqlVvFBJUOgTLEd11yP6Tk%3D")
  const [mousmi] = useState("https://orderfreshlife.blob.core.windows.net/vegies/mosambi-1-kg-product-images-o590001673-p590001673-0-202203150518.jpg.webp?sp=r&st=2024-03-16T23:05:55Z&se=2025-03-17T07:05:55Z&spr=https&sv=2022-11-02&sr=b&sig=lSKyypTJXCabiQjyY2S9a0W%2FrT1eHRVW6NeBUVPqdDY%3D")
  const [mashroom] = useState("https://orderfreshlife.blob.core.windows.net/vegies/mushroom.webp?sp=r&st=2024-03-16T23:06:17Z&se=2025-03-17T07:06:17Z&spr=https&sv=2022-11-02&sr=b&sig=9L1Hofw1SiCk6E2tGNSkf%2Bd%2BIO83FauVzPAHRw%2FEaWQ%3D")
  const [kerbooja] = useState("https://orderfreshlife.blob.core.windows.net/vegies/muskmelon.webp?sp=r&st=2024-03-16T23:07:04Z&se=2025-03-17T07:07:04Z&spr=https&sv=2022-11-02&sr=b&sig=CxxC7J3%2BR682d3T7KnJNpyPms6VX9pjEOy04%2Fm0di%2BE%3D")
  const [nimbu] = useState("https://orderfreshlife.blob.core.windows.net/vegies/nimbu.webp?sp=r&st=2024-03-16T23:07:24Z&se=2025-03-17T07:07:24Z&spr=https&sv=2022-11-02&sr=b&sig=fWPSBMaQNKdcqi7dRKA39v4sE0eZTDKm%2FzZeXIfMgEo%3D")
  const [onion] = useState("https://orderfreshlife.blob.core.windows.net/vegies/onion.webp?sp=r&st=2024-03-16T23:07:42Z&se=2025-03-17T07:07:42Z&spr=https&sv=2022-11-02&sr=b&sig=GSIvVpkfcsNtxeMzh24LpieO2FG9XU6HKAYv3JlY4Gs%3D")
  const [orange] = useState("https://orderfreshlife.blob.core.windows.net/vegies/oragne.webp?sp=r&st=2024-03-16T23:07:57Z&se=2025-03-17T07:07:57Z&spr=https&sv=2022-11-02&sr=b&sig=nsTfAlyGXGM9mhch0d9%2FVB5wMjYfKZUl8t%2BjO%2FjYi5o%3D")
  const [palak] = useState("https://orderfreshlife.blob.core.windows.net/vegies/palak.webp?sp=r&st=2024-03-16T23:08:13Z&se=2025-03-17T07:08:13Z&spr=https&sv=2022-11-02&sr=b&sig=5e%2BVqgc7o45NhxIXD8%2F7NawLjIWkhFUmyuEnNuP5EiE%3D")
  const [papita] = useState("https://orderfreshlife.blob.core.windows.net/vegies/papita.webp?sp=r&st=2024-03-16T23:08:36Z&se=2025-03-17T07:08:36Z&spr=https&sv=2022-11-02&sr=b&sig=mT3i2Mbq2U7vZp6mnAesTkecrgiVAGHt2UUMrG2gSiI%3D")
  const [parsley] = useState("https://orderfreshlife.blob.core.windows.net/vegies/parsely.webp?sp=r&st=2024-03-16T23:08:48Z&se=2025-03-17T07:08:48Z&spr=https&sv=2022-11-02&sr=b&sig=ncjp8EwTwa8rtHFse2iazNJG6LMEsbJuBO322iS5Atc%3D")
  const [peellasan] = useState("https://orderfreshlife.blob.core.windows.net/vegies/peeled%20lassun.webp?sp=r&st=2024-03-16T23:09:00Z&se=2025-03-17T07:09:00Z&spr=https&sv=2022-11-02&sr=b&sig=WXGSKOSdon0u7nEcNnHVRZStVRjhG%2F40g9Wdi%2FViUec%3D")
  const [ockchey] = useState("https://orderfreshlife.blob.core.windows.net/vegies/pockehy%20bockhey.jpg.webp?sp=r&st=2024-03-16T23:09:12Z&se=2025-03-17T07:09:12Z&spr=https&sv=2022-11-02&sr=b&sig=dOf6heAe6wL9Z5k9yES6pG6K2pr924FT5q108Rp3Pqw%3D")
  const [anar] = useState("https://orderfreshlife.blob.core.windows.net/vegies/pomegernate.webp?sp=r&st=2024-03-16T23:09:25Z&se=2025-03-17T07:09:25Z&spr=https&sv=2022-11-02&sr=b&sig=fwyQSkoxzJ6u4FiTT94C6qloGT5XDwOX%2F1FBSr622Gc%3D")
  const [patato] = useState("https://orderfreshlife.blob.core.windows.net/vegies/potato.webp?sp=r&st=2024-03-16T23:09:36Z&se=2025-03-17T07:09:36Z&spr=https&sv=2022-11-02&sr=b&sig=NohiZm2ENvAhnr1nNm66gQUyAQziLijmgj2Ih489aBs%3D")
  const [rapsberry] = useState("https://orderfreshlife.blob.core.windows.net/vegies/rapsberry.webp?sp=r&st=2024-03-16T23:09:49Z&se=2025-03-17T07:09:49Z&spr=https&sv=2022-11-02&sr=b&sig=X4up7UnE8ijpVa8w46rS2tqEY6tWc3ykfkghnTiRvTs%3D")
  const [rawcado] = useState("https://orderfreshlife.blob.core.windows.net/vegies/raw%20avacado.jpg.webp?sp=r&st=2024-03-16T23:10:03Z&se=2025-03-17T07:10:03Z&spr=https&sv=2022-11-02&sr=b&sig=Aw1Oo0CUhqEcZbb7MApKEPlaUc9xdrdj9ZYyE1lWMO0%3D")
  const [rawpine] = useState("https://orderfreshlife.blob.core.windows.net/vegies/rawpineapple.webp?sp=r&st=2024-03-16T23:10:30Z&se=2025-03-17T07:10:30Z&spr=https&sv=2022-11-02&sr=b&sig=KJc8G2%2BM%2BOuJuRB%2B7hMwjqqMWY9Rx6szZjjWUpYTNRk%3D")
  const [redcabbage] = useState("https://orderfreshlife.blob.core.windows.net/vegies/redcabbage.webp?sp=r&st=2024-03-16T23:10:43Z&se=2025-03-17T07:10:43Z&spr=https&sv=2022-11-02&sr=b&sig=%2BwFHAdPXaduSOlad1OhvMaihWHXKE0Yncl6N98G7XW4%3D")
  const [ripcado] = useState("https://orderfreshlife.blob.core.windows.net/vegies/ripen%20avacado.webp?sp=r&st=2024-03-16T23:10:59Z&se=2025-03-17T07:10:59Z&spr=https&sv=2022-11-02&sr=b&sig=ewFbsYWWkxAGvzO0zpZFtM%2B2p98oABqETzCzGBXo3Fo%3D")
  const [saag] = useState("https://orderfreshlife.blob.core.windows.net/vegies/sarsokasaag.webp?sp=r&st=2024-03-16T23:11:11Z&se=2025-03-17T07:11:11Z&spr=https&sv=2022-11-02&sr=b&sig=PPWjaRePtx6LSmYWDAXbFRqYhrNJm7xPxu5lOhQFU4w%3D")
  const [shalgam] = useState("https://orderfreshlife.blob.core.windows.net/vegies/sarsokasaag.webp?sp=r&st=2024-03-16T23:11:11Z&se=2025-03-17T07:11:11Z&spr=https&sv=2022-11-02&sr=b&sig=PPWjaRePtx6LSmYWDAXbFRqYhrNJm7xPxu5lOhQFU4w%3D")
  const [sharda] = useState("https://orderfreshlife.blob.core.windows.net/vegies/shardhafruit.webp?sp=r&st=2024-03-16T23:11:36Z&se=2025-03-17T07:11:36Z&spr=https&sv=2022-11-02&sr=b&sig=7KrUeY9hibJcd9FiNVbhgJVdWjzlHnmpJChBB16ag24%3D")
  const [shimla] = useState("https://orderfreshlife.blob.core.windows.net/vegies/shimla.webp?sp=r&st=2024-03-16T23:11:48Z&se=2025-03-17T07:11:48Z&spr=https&sv=2022-11-02&sr=b&sig=37fIV1YLcyzj9SUA%2BRe7eEORalgtbf0yl5wh4tp%2Fvs0%3D")
  const [sitta] = useState("https://orderfreshlife.blob.core.windows.net/vegies/sitafal.webp?sp=r&st=2024-03-16T23:12:01Z&se=2025-03-17T07:12:01Z&spr=https&sv=2022-11-02&sr=b&sig=dUYXfX3uzTcCrs4AGl3F6C3C51DwK7sdIhy9OihdvEo%3D")
  const [soyachaap] = useState("https://orderfreshlife.blob.core.windows.net/vegies/soya%20chaap.webp?sp=r&st=2024-03-16T23:12:20Z&se=2025-03-17T07:12:20Z&spr=https&sv=2022-11-02&sr=b&sig=%2BINvdfF1C1Gmo7nPGEepgoi36M%2Bkhd6ROZVtTe%2FHt40%3D")
  const [soyapanner] = useState("https://orderfreshlife.blob.core.windows.net/vegies/soya%20paneer.jpg.webp?sp=r&st=2024-03-16T23:12:32Z&se=2025-03-17T07:12:32Z&spr=https&sv=2022-11-02&sr=b&sig=tz%2BiheaH7bJu39qffdYWX2lDLdQUEsa5mRgA%2BFak3WM%3D")
  const [strawberry] = useState("https://orderfreshlife.blob.core.windows.net/vegies/strawberry.webp?sp=r&st=2024-03-16T23:12:47Z&se=2025-03-17T07:12:47Z&spr=https&sv=2022-11-02&sr=b&sig=ewKzvXWHvcrRFGy2cizRQE08v%2FNGFX1ECUvkvGEJIJo%3D")
  const [paitha] = useState("https://orderfreshlife.blob.core.windows.net/vegies/tempImageDFU1ev.webp?sp=r&st=2024-03-16T23:13:22Z&se=2025-03-17T07:13:22Z&spr=https&sv=2022-11-02&sr=b&sig=9DhZyNVPpL5t8csCmmsnS2QW2rlUcZkcNQgLj3zBNFU%3D")
  const [tinda] = useState("https://orderfreshlife.blob.core.windows.net/vegies/tinda.webp?sp=r&st=2024-03-16T23:13:42Z&se=2025-03-17T07:13:42Z&spr=https&sv=2022-11-02&sr=b&sig=xe02HJOpLo7GpKyxkthmz%2F1eLqlCOH1426vCpk073Uw%3D")
  const [tomato] = useState("https://orderfreshlife.blob.core.windows.net/vegies/tomato.webp?sp=r&st=2024-03-16T23:14:03Z&se=2025-03-17T07:14:03Z&spr=https&sv=2022-11-02&sr=b&sig=vC2N4bqvYx7fqwk5AZZ%2F6oKQK1TcVP4AIhfMWOX4IFk%3D")
  const [tori] = useState("https://orderfreshlife.blob.core.windows.net/vegies/tori.webp?sp=r&st=2024-03-16T23:14:16Z&se=2025-03-17T07:14:16Z&spr=https&sv=2022-11-02&sr=b&sig=un3FRiIfPqwsoTlnZMnY0%2FfRIGfSKqOQR0l%2FZiZ6RY0%3D")
  const [watermelon] = useState("https://orderfreshlife.blob.core.windows.net/vegies/watermelon.webp?sp=r&st=2024-03-16T23:14:28Z&se=2025-03-17T07:14:28Z&spr=https&sv=2022-11-02&sr=b&sig=SdrioEKCYf%2BJ3U2YwLJhT%2FpyWwakCWThfmudA5u8OvI%3D")
  const [yellowcap] = useState("https://orderfreshlife.blob.core.windows.net/vegies/yellow%20shimla.webp?sp=r&st=2024-03-16T23:14:46Z&se=2025-03-17T07:14:46Z&spr=https&sv=2022-11-02&sr=b&sig=23G24nx7TwJe1CtWadLKoauD3fnXqRRfEIl0B5Qb70s%3D")
  const [zuc] = useState("https://orderfreshlife.blob.core.windows.net/vegies/zucchini-green-approx-225-g-300-g-product-images-o590003901-p590041416-0-202203151959.jpg.webp?sp=r&st=2024-03-16T23:15:02Z&se=2025-03-17T07:15:02Z&spr=https&sv=2022-11-02&sr=b&sig=Xw9ROGklOUjCx4Taz12yTJWuQM7cHY8fR9UNJC7PGqM%3D")
 
  const [listcart, setlistcart] = useState(false)
  const [RegisterationPanel, setRegisterationpanel] = useState(false)



  function showList(){
    setlistcart(listcart=> !listcart)
  }

  useEffect(el=>{
    props.loaderSet(true)
    axios({
      method : 'post',
      url : 'https://wheelb2cback.onrender.com/api/v1/product/all-products',
      data : {
        token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout'
      }
    }).then(res=>{
      if(res.data.status==='success'){
        props.loaderSet(false)
        setList(list=> res.data.data.items)
        setItems(items=> res.data.data.items)
        setgrossPrice(grossPrice=> res.data.data.price)
        setExistingCart(existingCart=> res.data.data.nameArr)
        props.cartCountupdate(res.data.data.nameArr.length)
        /// filtering the elements if we get the catagory from expore section in main page
        if(props.filterpass){

          const filter_catagory = props.filterpass ? props.filterpass : 'all'
          document.querySelector('.filterpassing').value = filter_catagory 
          
          if(filter_catagory==='all'){
            setItems(items=> list)
            return
          }
          const newArr = res.data.data.items.filter((el)=>{
            if(el.variety===filter_catagory){
              return el
            }
          })
          setItems(items=> newArr)
        }

        // search the item that comes from main page search
        if(props.mainPageSearchImplementation){
          const newArr = res.data.data.items.filter((el)=>{
            if(el.name == (props.mainPageSearchImplementation.toLowerCase())){
              return el
            }
          })
          setItems(items=> newArr)
        }

      }
    })
  }, [])


  useEffect(el=>{
    axios({
      method : 'POST',
      url : 'https://wheelb2cback.onrender.com/api/v1/user/list-of-elements-in-cart',
      data : {
        token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout'
      }
    }).then(res=>{
      if(res.data.status==='success'){
        setCoreCartList(coreCartList=> res.data.data.cart)

      }
    })
  }, [items])

  function filterItems(){
    const filter_catagory = filter_cat.current.value
    if(filter_catagory==='all'){
      setItems(items=> list)
      return
    }
    console.log(list)
    const newArr = list.filter((el)=>{
      if(el.variety===filter_catagory){
        return el
      }
    })
    setItems(items=> newArr)
  }

  function openingRegisterationPanel(cond){
    setRegisterationpanel(RegisterationPanel=> false)
  }

  function addTocart(el) {
    const itemName = el.target.parentNode.parentNode.children[0].children[1].children[0].textContent
    let units = 0;
    if(itemName === 'kiwi' || itemName === 'avacado' || itemName === 'banana' || itemName === 'dragon' || itemName === 'pineapple rani' || itemName === 'raja pineapple' || itemName === 'sharda' || itemName === 'papita' || itemName === 'kharbooja' || itemName === 'water melon'){
      units = 1;
    }else if(itemName === 'red capsicum' || itemName === 'yellow capsicum' || itemName === 'allo' || itemName === 'arbi' || itemName === 'amla' || itemName === 'baigan' || itemName === 'bhindi' || itemName === 'beans' || itemName === 'gajar' || itemName === 'goal giya' || itemName === 'hara dhaniya' || itemName === 'hari mirch' || itemName === 'hara payaz' || itemName === 'halwa kaddu' || itemName === 'kheera china' || itemName === 'karela' || itemName === 'lokki' || itemName === 'kheera desi' || itemName === 'mooli' || itemName === 'onion' || itemName === 'patta gobhi' || itemName === 'pudina' || itemName === 'phool gobhi' || itemName === 'palak' || itemName === 'shimla mirch' || itemName === 'zimikhand' || itemName === 'bhe' || itemName === 'turi' || itemName === 'tomato'){
      units = 0.25;
    }else if(itemName === 'nimbu' || itemName === 'basil' || itemName === 'adrak' || itemName === 'lasan' || itemName === 'parsely' || itemName === 'rose merry' || itemName === 'thym'){
      units = 0.10;
    }else if(itemName === 'cherry tomato' || itemName === 'lettuce' || itemName === 'cheery' || itemName === 'baby corn' || itemName === 'celery' || itemName === 'pokchey' || itemName === 'leeks' || itemName === 'mashroom'){
      units = 0.20;
    }else if(itemName === 'beet root' || itemName === 'orange' || itemName === 'mausmi' || itemName === 'mango' || itemName === 'grapes' || itemName === 'babbu gosha' || itemName === 'amrood' || itemName === 'anar' || itemName === 'apple' || itemName === 'red cabbage' || itemName === 'peeled garlic' || itemName === 'khatal' || itemName === 'broccoli' || itemName === 'frozen mattar' || itemName === 'china cabbage' || itemName === 'ice berg' || itemName === 'zucchini green' || itemName === 'zucchini yellow'){
      units = 0.50;
    }else if(itemName === 'half boiled noodles' || itemName === 'french fries' || itemName === 'masala fries' ||  itemName === 'soya chaap' || itemName === 'sweet corn' || itemName === 'frozen vegetables'){
      units = 1;
    }
    axios({
      method: "POST",
      url: "https://wheelb2cback.onrender.com/api/v1/user/add-to-cart",
      data: {
        itemName:
          el.target.parentNode.parentNode.children[0].children[1].children[0]
            .textContent,
        price:
          el.target.parentNode.parentNode.children[0].children[1].children[1]
            .textContent,
        units: units,
        token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout'
      },
    }).then((res) => {
        if (res.data.status === "success") {
          setItems(items=> res.data.data.items)
          setList(list=> res.data.data.items)
          setExistingCart(existingCart=> res.data.data.nameArr)
          setgrossPrice(grossPrice=> res.data.data.pricegross)
          props.cartCountupdate(res.data.data.nameArr.length)
          utilError(res.data.data.message)
        }else{
          setRegisterationpanel(RegisterationPanel=> true)
        }
      });
    }

    function search(){
      const filter_name = searchname.current.value || props.mainPageSearchImplementation;
      const newArr = list.filter((el)=>{
        if(el.name.includes(filter_name.toLowerCase())){
          return el
        }
      })
      setItems(items=> newArr)
    }

    function add(el){
      const val = el.target.parentNode.children[1];
      console.log(val)
      const name = el.target.parentNode.parentNode.parentNode.children[0].children[1].children[0].textContent;
      if(name === 'kiwi' || name === 'avacado' || name === 'banana' || name === 'dragon' || name === 'pineapple rani' || name === 'raja pineapple' || name === 'sharda' || name === 'papita' || name === 'kharbooja' || name === 'water melon'){
        val.textContent = (Number(val.textContent) + 1).toFixed(1);
      }else if(name === 'red capsicum' || name === 'yellow capsicum' || name === 'allo' || name === 'arbi' || name === 'amla' || name === 'baigan' || name === 'bhindi' || name === 'beans' || name === 'gajar' || name === 'goal giya' || name === 'hara dhaniya' || name === 'hari mirch' || name === 'hara payaz' || name === 'halwa kaddu' || name === 'kheera china' || name === 'karela' || name === 'lokki' || name === 'kheera desi' || name === 'mooli' || name === 'onion' || name === 'patta gobhi' || name === 'pudina' || name === 'phool gobhi' || name === 'palak' || name === 'shimla mirch' || name === 'zimikhand' || name === 'bhe' || name === 'turi' || name === 'tomato'){
        val.textContent = (Number(val.textContent) + 0.25).toFixed(1);
      }else if(name === 'nimbu' || name === 'adrak' || name === 'lasan' || name === 'basil' || name === 'parsely' || name === 'rose merry' || name === 'thym'){
        val.textContent = (Number(val.textContent) + 0.10).toFixed(1);
      }else if(name === 'cherry tomato' || name === 'lettuce' || name === 'cheery' || name === 'baby corn' || name === 'celery' || name === 'pokchey' || name === 'leeks' || name === 'leeks' || name === 'mashroom'){
        val.textContent = (Number(val.textContent) + 0.20).toFixed(1);
      }else if(name === 'frozen mattar' || name === 'beet root' || name === 'orange' || name === 'mausmi' || name === 'mango' || name === 'grapes' || name === 'babbu gosha' || name === 'amrood' || name === 'anar' || name === 'apple' || name === 'red cabbage' || name === 'peeled garlic' || name === 'khatal' || name === 'broccoli' || name === 'china cabbage' || name === 'ice berg' || name === 'zucchini green' || name === 'zucchini yellow'){
        val.textContent = (Number(val.textContent) + 0.50).toFixed(1);
      }else if(name === 'half boiled noodles' || name === 'french fries' || name === 'masala fries' ||  name === 'soya chaap' || name === 'sweet corn' || name === 'frozen vegetables'){
        val.textContent = (Number(val.textContent) + 1).toFixed(1);
      }
      axios({
        method: "POST",
        url: "https://wheelb2cback.onrender.com/api/v1/user/cart-update",
        data: {
          name: name,
          units: val.textContent,
          token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout'
        },
      }).then(res=>{
        if(res.data.status==='success'){
          setItems(items=> res.data.data.items)
          setList(list=> res.data.data.items)
          setgrossPrice(grossPrice=> res.data.data.price)
        }
      })

    }


    function substract(el){
      const val = el.target.parentNode.children[1];
      console.log(val)
      if(val.textContent===0.0){
        return
      }
      const name = el.target.parentNode.parentNode.parentNode.children[0].children[1].children[0].textContent;
      if(name === 'kiwi' || name === 'avacado' || name === 'banana' || name === 'dragon' || name === 'pineapple rani' || name === 'raja pineapple' || name === 'sharda' || name === 'papita' || name === 'kharbooja' || name === 'water melon'){
        val.textContent = (Number(val.textContent) - 1).toFixed(1);
      }else if(name === 'red capsicum' || name === 'yellow capsicum' || name === 'allo' || name === 'arbi' || name === 'amla' || name === 'baigan' || name === 'bhindi' || name === 'beans' || name === 'gajar' || name === 'goal giya' || name === 'hara dhaniya' || name === 'hari mirch' || name === 'hara payaz' || name === 'halwa kaddu' || name === 'kheera china' || name === 'karela' || name === 'lokki' || name === 'kheera desi' || name === 'mooli' || name === 'onion' || name === 'patta gobhi' || name === 'pudina' || name === 'phool gobhi' || name === 'palak' || name === 'shimla mirch' || name === 'zimikhand' || name === 'bhe' || name === 'turi' || name === 'tomato'){
        val.textContent = (Number(val.textContent) - 0.25).toFixed(1);
      }else if(name === 'nimbu' || name === 'adrak' || name === 'lasan' || name === 'basil' || name === 'parsely' || name === 'rose merry' || name === 'thym'){
        val.textContent = (Number(val.textContent) - 0.10).toFixed(1);
      }else if(name === 'cherry tomato' || name === 'lettuce' || name === 'cheery' || name === 'baby corn' || name === 'celery' || name === 'pokchey' || name === 'leeks' || name === 'leeks' || name === 'mashroom'){
        val.textContent = (Number(val.textContent) - 0.20).toFixed(1);
      }else if(name === 'frozen mattar' || name === 'beet root' || name === 'orange' || name === 'mausmi' || name === 'mango' || name === 'grapes' || name === 'babbu gosha' || name === 'amrood' || name === 'anar' || name === 'apple' || name === 'red cabbage' || name === 'peeled garlic' || name === 'khatal' || name === 'broccoli' || name === 'china cabbage' || name === 'ice berg' || name === 'zucchini green' || name === 'zucchini yellow'){
        val.textContent = (Number(val.textContent) - 0.50).toFixed(1);
      }else if(name === 'half boiled noodles' || name === 'french fries' || name === 'masala fries' ||  name === 'soya chaap' || name === 'sweet corn' || name === 'frozen vegetables'){
        val.textContent = (Number(val.textContent) - 1).toFixed(1);
      }

      axios({
        method: "POST",
        url: "https://wheelb2cback.onrender.com/api/v1/user/cart-update",
        data: {
          name: name,
          units: val.textContent,
          token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout'
        },
      }).then(res=>{
        if(res.data.status==='success'){
          setItems(items=> res.data.data.items)
          setList(list=> res.data.data.items)
          setgrossPrice(grossPrice=> res.data.data.price)

        }
      })

    }


  return (
    <>
      <div className="all-products pad16 flex flex-dir gap16">
        
        {RegisterationPanel && <props.AccountRegisteration openingRegisterationPanel={openingRegisterationPanel} />}
        
        <h2 className="allProd">All products</h2>
        <div className="flex flex-1 gap8">
          <div className="search">
            <input ref={searchname} onChange={search} className="search inp inp__updated" placeholder="search" type="text"/>
          </div>
          <div className="filter flex flex-dir gap8">
            {/* <p className="filterhead">Filter Items</p> */}
            <select onChange={filterItems} ref={filter_cat} className="filterpassing inp inp__updated">
              <option className="opt" value="all">
                Choose filter
              </option>
              <option className="opt" value="all">
                All products
              </option>
              <option className="opt" value="fruits">
                Fruits
              </option>
              <option className="opt" value="vegetables">
                Vegetables
              </option>
              <option className="opt" value="english">
                English items
              </option>
              <option className="opt" value="frozen">
                Packed food
              </option>
            </select>
          </div>

        </div>
        {existingCart.length>0 &&
          <div className="cartShort flex flex-dir">
            <div className=" flex flex-2 pad16">
              <div className="boxCart pad16">
                <p className="cartItemscount">Cart - {existingCart.length} Items - ₹{grossPrice}/-</p>
              </div>
              <div className="flex flex-1 gap16">
                <img onClick={showList} src={List} className="icon icon__" alt="delete"/>
              </div>
            </div>
          </div>
        }

        {listcart &&
          <div className="listDisplay pad16 flex flex-dir gap8">
            <p className="listHead">Items in cart</p>
            <div className="grid grid-2-col gap8">
              {coreCartList.map(el=>
                <p className="itemincart">{el.itemName} - {el.units}kg</p>  
              )}
            </div>
          </div>
        }
        
        <div className="products flex flex-dir gap16 pad16">
          {items.length===0 && <div className="flex flex-1 flex-dir gap16">
              <img src={cross} alt="close" className="noProduct"/>
              <p className="noprodname">No product found !</p>  
          </div>}
          {items.map((el) => (
            <div key={el._id} className="prod pad16 flex flex-2">
              {el.price===0 &&
                  <div className="outOffStock flex flex-1">
                    <p className="name outStock">out of stock</p>
                  </div>
              }
              <div className="flex flex-1 flex-dir gap8">
                {el.name==='allo' && <img src={patato} className="icon" alt="vegetable" />}
                {el.name==='adrak' && <img src={adrak} className="icon" alt="vegetable" />}
                {el.name==='arbi' && <img src={arbi} className="icon" alt="vegetable" />}
                {el.name==='amla' && <img src={amla} className="icon" alt="vegetable" />}
                {el.name==='baigan' && <img src={baigan} className="icon" alt="vegetable" />}
                {el.name==='bhindi' && <img src={bhindi} className="icon" alt="vegetable" />}
                {el.name==='beans' && <img src={beans} className="icon" alt="vegetable" />}
                {el.name==='gajar' && <img src={carrot} className="icon" alt="vegetable" />}
                {el.name==='goal giya' && <img src={golkaddu} className="icon" alt="vegetable" />}
                {el.name==='hara dhaniya' && <img src={dhaina} className="icon" alt="vegetable" />}
                {el.name==='hari mirch' && <img src={noimg} className="icon" alt="vegetable" />}
                {el.name==='hara payaz' && <img src={greenOnion} className="icon" alt="vegetable" />}
                {el.name==='halwa kaddu' && <img src={longkaddu} className="icon" alt="vegetable" />}
                {el.name==='kheera china' && <img src={khera} className="icon" alt="vegetable" />}
                {el.name==='karela' && <img src={karela} className="icon" alt="vegetable" />}
                {el.name==='lokki' && <img src={longkaddu} className="icon" alt="vegetable" />}
                {el.name==='lasan' && <img src={lasan} className="icon" alt="vegetable" />}
                {el.name==='kheera desi' && <img src={khera} className="icon" alt="vegetable" />}
                {el.name==='mooli' && <img src={mooli} className="icon" alt="vegetable" />}
                {el.name==='mashroom' && <img src={mashroom} className="icon" alt="vegetable" />}
                {el.name==='nimbu' && <img src={nimbu} className="icon" alt="vegetable" />}
                {el.name==='onion' && <img src={onion} className="icon" alt="vegetable" />}
                {el.name==='patta gobhi' && <img src={noimg} className="icon" alt="vegetable" />}
                {el.name==='pudina' && <img src={mint} className="icon" alt="vegetable" />}
                {el.name==='phool gobhi' && <img src={fulgobi} className="icon" alt="vegetable" />}
                {el.name==='palak' && <img src={palak} className="icon" alt="vegetable" />}
                {el.name==='shimla mirch' && <img src={shimla} className="icon" alt="vegetable" />}
                {el.name==='zimikhand' && <img src={zuc} className="icon" alt="vegetable" />}
                {el.name==='bhe' && <img src={bhe} className="icon" alt="vegetable" />}
                {el.name==='khatal' && <img src={jack} className="icon" alt="vegetable" />}
                {el.name==='turi' && <img src={tori} className="icon" alt="vegetable" />}
                {el.name==='tomato' && <img src={tomato} className="icon" alt="vegetable" />}
                {el.name==='baby corn' && <img src={corn} className="icon" alt="vegetable" />}
                {el.name==='basil' && <img src={basil} className="icon" alt="vegetable" />}
                {el.name==='broccoli' && <img src={broccli} className="icon" alt="vegetable" />}
                {el.name==='cherry tomato' && <img src={cherryTomato} className="icon" alt="vegetable" />}
                {el.name==='celery' && <img src={celery} className="icon" alt="vegetable" />}
                {el.name==='china cabbage' && <img src={redcabbage} className="icon" alt="vegetable" />}
                {el.name==='ice berg' && <img src={iceberg} className="icon" alt="vegetable" />}
                {el.name==='lettuce' && <img src={noimg} className="icon" alt="vegetable" />}
                {el.name==='parsely' && <img src={parsley} className="icon" alt="vegetable" />}
                {el.name==='pokchey' && <img src={parsley} className="icon" alt="vegetable" />}
                {el.name==='peeled garlic' && <img src={peellasan} className="icon" alt="vegetable" />}
                {el.name==='red capsicum' && <img src={redcap} className="icon" alt="vegetable" />}
                {el.name==='yellow capsicum' && <img src={yellowcap} className="icon" alt="vegetable" />}
                {el.name==='red cabbage' && <img src={redcabbage} className="icon" alt="vegetable" />}
                {el.name==='zucchini green' && <img src={zuc} className="icon" alt="vegetable" />}
                {el.name==='zucchini yellow' && <img src={noimg} className="icon" alt="vegetable" />}
                {el.name==='apple' && <img src={apple} className="icon" alt="vegetable" />}
                {el.name==='anar' && <img src={anar} className="icon" alt="vegetable" />}
                {el.name==='amrood' && <img src={amrood} className="icon" alt="vegetable" />}
                {el.name==='banana' && <img src={kela} className="icon" alt="vegetable" />}
                {el.name==='babbu gosha' && <img src={kela} className="icon" alt="vegetable" />}
                {el.name==='grapes' && <img src={grapes} className="icon" alt="vegetable" />}
                {el.name==='kharbooja' && <img src={kerbooja} className="icon" alt="vegetable" />}
                {el.name==='kiwi' && <img src={kiwi} className="icon" alt="vegetable" />}
                {el.name==='mango' && <img src={mango} className="icon" alt="vegetable" />}
                {el.name==='mausmi' && <img src={mousmi} className="icon" alt="vegetable" />}
                {el.name==='orange' && <img src={orange} className="icon" alt="vegetable" />}
                {el.name==='pineapple rani' && <img src={raniPine} className="icon" alt="vegetable" />}
                {el.name==='papita' && <img src={papita} className="icon" alt="vegetable" />}
                {el.name==='beet root' && <img src={beetroot} className="icon" alt="vegetable" />}
                {el.name==='sharda' && <img src={sharda} className="icon" alt="vegetable" />}
                {el.name==='water melon' && <img src={watermelon} className="icon" alt="vegetable" />}
                {el.name==='french fries' && <img src={fries} className="icon" alt="vegetable" />}
                {el.name==='frozen mattar' && <img src={frozenMattar} className="icon" alt="vegetable" />}
                {el.name==='soya chaap' && <img src={soyachaap} className="icon" alt="vegetable" />}
                {el.name==='sweet corn' && <img src={corn} className="icon" alt="vegetable" />}
                {el.name==='half boiled noodles' && <img src={noodles} className="icon" alt="vegetable" />}
                
                <div className="flex flex-dir flex-1 gap8">
                  <p className="price">{el.name}</p>
                  {el.name === 'kiwi' || el.name === 'avacado' || el.name === 'banana' || el.name === 'dragon' || el.name === 'rani pineapple' || el.name === 'raja pineapple' || el.name === 'sharda' || el.name === 'papita' || el.name === 'kharbooja' || el.name === 'water melon' ? <p className="price">{el.price}/- piece</p> : <p className="price">{el.price}/- kg</p> }
                </div>
              </div>
              <div className="flex flex-dir gap16">
              {!existingCart.includes(el.name) ?
                  <p className="Update_quantity" >Add to cart</p>
                :
                  <>
                    <p className="Update_quantity">Update quantity</p>
                    {el.name === 'kiwi' || el.name === 'avacado' || el.name === 'banana' || el.name === 'dragon' || el.name === 'pineapple rani' || el.name === 'raja pineapple' || el.name === 'sharda' || el.name === 'papita' || el.name === 'kharbooja' || el.name === 'water melon' ?
                      <div className="countbox flex flex-1 gap8">
                        <button onClick={substract} className="add">
                          -
                        </button>
                        <p className="count">{el.quantity}</p>
                        <button onClick={add} className="add">
                          +
                        </button>
                        <p className="unitsInCartupdate">piece</p>
                      </div>
                    : 
                      <div className="countbox flex flex-1 gap8">
                        <button onClick={substract} className="add">
                          -
                        </button>
                        <p className="count">{el.quantity}</p>
                        
                        <button onClick={add} className="add">
                          +
                        </button>
                        <p className="unitsInCartupdate">kg</p>
                      </div>
                    }
                  </>                  
                }
                {el.price!==0 ?
                  <>
                    {!existingCart.includes(el.name) &&
                      <button
                        onClick={addTocart}
                        className="border_btn border_btn_fill"
                      >
                      +
                      </button>
                    }
                  </>
                : 
                  <button className="addcart">
                    soon...
                  </button>
                }
                
                
              </div>
            </div>
          ))}
          <Link to='/cart' className="cartAdd border_btn border_btn_fill">Go to cart</Link>
        </div>
      </div>
      <div className="bottom">
        <BottomMenu count={props.cartCount} />
      </div>
    </>
  );
}

export default AllProducts;
