import './../util.css'
import './refundOrder.css'
import BottomMenu from './bottomMenu'
import axios from 'axios'
import { useEffect, useState } from 'react'

function RefundOrders(){
    const [RefundOrder, setRefundOrders] = useState([])

    useEffect(el=>{
        axios({
            method : 'POST',
            url : 'https://wheelb2cback.onrender.com/api/v1/user/refund-orders',
            data : {
                token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout'
            }
        }).then(res=>{
            if(res.data.status==='success'){
                let refundOrders = []
                res.data.data.refundOrders.forEach(el=>{
                    refundOrders.push(...el.refundOrder)
                })
                console.log(refundOrders)
                setRefundOrders(RefundOrder=> refundOrders)
            }
        })
    }, [])

    return(
        <div className="refundOrders">
            <div className='refundOrdersbox pad16 flex flex-dir gap16'>
                <h2 className='refundHeader'>Your refund orders</h2>
                {RefundOrder.map(el=>
                    <div className='order pad16 grid grid-1-col gap8'>
                        <p className='id'>Order id - {el._id}</p>
                        <p className='id'>Order date - {el.date}</p>
                        <p className='id'>Payment id - {el.razorpay_payment_id}</p>
                        {el.total > 200 && 
                            <p className='id'>Refund Amount - {el.total - 0.02*el.total}/-</p>
                        }
                        {el.total < 200 && 
                            <p className='id'>Refund Amount - {el.total - 0.05*el.total}/-</p>
                        }
                        <p className='id'>Refund status - pending</p>
                        <p className='id'>You will get your refund in 3-5 working days</p>
                    </div>
                )}
            </div>
            <div className='bottom'>
                <BottomMenu/>
            </div>
        </div>
    )
}

export default RefundOrders