import './bottom.css'
import './../util.css'
import Carrot from './../icon/carrot.png'
import Orange from './../icon/orange.png'
import All from './../icon/all.png'
import Male from './../icon/male.png'
import Home from './../icon/home.png'
import Cart from './../icon/cart1.png'
import done from './../icon/check.png'
import plus from './../icon/plus.png'
import house from './../icon/house.png'
import user from './../icon/user.png'
import ReactWhatsapp from 'react-whatsapp';

import { Link } from 'react-router-dom'
import {useState, useEffect} from 'react'
import axios from 'axios'

function BottomMenu(props){


    const [activecart, setactiveacrt] = useState(0)

    function setCart1(){
        setactiveacrt(activecart=> 1)
    }

    function setCart2(){
        setactiveacrt(activecart=> 2)
    }

    // useEffect(el=>{
    //     document.querySelector('.counts').style.opacity = '0'
    // }, [])

    // function code(){
    //     axios({
    //         method : 'GET',
    //         url : 'http://127.0.0.1:5000/code'
    //     })
    // }

    const showInMapClicked = () => {
        window.open("https://maps.google.com?q=" + 31.399453 + "," + 75.535907 );
      };

    

    
    return(
            <div className='cover gap8'>
                
                <div className="menuBottom pad16 flex flex-2 gap72">
                    {/* <ReactWhatsapp number="1-212-736-5000" message="Hello World!!!" /> */}
                    <div className='circleButton flex flex-1 pad8'>
                        <Link to='/all-products' className='cred menuBtn flex flex-dir flex-1 gap8'>
                            <img src={plus} alt='food' className='icon icon__'/>
                            <label className='label label__'>order</label>

                        </Link>
                    </div>
                    <div className='cover flex flex-1 gap16'>
                        <Link onClick={setCart1} to='/' className={activecart===1 ? 'cred menuBtn flex flex-dir flex-1 gap8 active' : 'cred menuBtn flex flex-dir flex-1 gap8'} >
                            <img src={house} alt='food' className='icon icon__'/>
                            <label className='label label__'>Home</label>
                        </Link>
                        
                        
                        <Link to='/placed' className='cred menuBtn flex flex-dir flex-1 gap8'>
                            <img src={done} alt='food' className='icon icon__'/>
                            <label className='label label__'>place orders</label>
                        </Link>
                    </div>
                    <div className='cover flex flex-1 gap16'>
                        
                        <Link onClick={setCart2} to='/cart' className={activecart===2 ? 'carticon cred menuBtn flex flex-dir flex-1 gap8 active' : 'carticon cred menuBtn flex flex-dir flex-1 gap8'}>
                            {props.count>0 && <p className='counts'>{props.count}</p>}
                            <img src={Cart} alt='food' className='icon icon__'/>
                            <label className='label label__'>Cart</label>
                        </Link>
                        <Link to='/setting' className='cred menuBtn flex flex-dir flex-1 gap8'>
                            <img src={user} alt='food' className='icon icon__'/>
                            <label className='label label__'>Account</label>
                        </Link>
                        
                    </div>

                    
                </div>
                {/* <div className='flasj flex flex-1'>
                    <p className='names'>OrderFreshLife</p>

                </div> */}
            </div>
    )
}

export default BottomMenu;