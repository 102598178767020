import './../util.css'
import './refund.css'

function Refund(){
    return(
        <div className='refund pad16 flex flex-dir gap8'>
            <p>Return and Refund Policy</p>

<p>Our Return and Refund Policy was last updated 17/03/2024</p>

<p>Thank you for shopping at www.orderfreshlife.com.</p>

<p>If, for any reason, You are not completely satisfied with a purchase We invite You to review our policy on refunds and returns. This Return and Refund Policy was generated by TermsFeed Return and Refund Policy Template.</p>

<p>The following terms are applicable for any products that You purchased with Us.</p>

<p>Interpretation and Definitions</p>

<p>Interpretation</p>

<p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

<p>Definitions</p>

<p>For the purposes of this Return and Refund Policy:</p>

<p>&quot;Company&quot; (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to www.orderfreshlife.com</p>

<p>&quot;Goods&quot; refers to the items offered for sale on the Service.</p>

<p>&quot;Orders&quot; means a request by You to purchase Goods from Us.</p>

<p>&quot;Service&quot; refers to the Website.</p>

<p>&quot;Website&quot; refers to orderfreshlife, accessible from www.orderfreshlife.com</p>

<p>&quot;You&quot; means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>

<p>Your Order Cancellation Rights</p>

<p>You are entitled to cancel Your Order within 14 days without giving any reason for doing so.</p>

<p>The deadline for cancelling an Order is 14 days from the date on which You received the Goods or on which a third party you have appointed, who is not the carrier, takes possession of the product delivered.</p>

<p>In order to exercise Your right of cancellation, You must inform Us of your decision by means of a clear statement. You can inform us of your decision by:</p>

<p>By visiting this page on our website: www.orderfreshlife.com By sending us an email: orderfreshlife@gmail.com the user are able to cancel the order within the same of order till 11:59pm, the refund in case of virtual payment will be issued in 3-56 working days.</p>

<p>Conditions for Returns</p>

<p>In case user want to return the goods he/she has to follow the following steps 1.) User have to raise complain in app or website complain panel with proper subject and description of the complain. 2.) Our refund team will reach out the person, if there is genieun problem it would be handled</p>

<p>The refund amount be not be credited completelly , the return fees is cutted down upto 5%-10% Returning Goods</p>

<p>You are responsible for the cost and risk of returning the Goods to Us. You should send the Goods at the following address:</p>

<p>[sabzi mandi jalandhar]</p>

<p>We cannot be held responsible for Goods damaged or lost in return shipment. Therefore, We recommend an insured and trackable mail service. We are unable to issue a refund without actual receipt of the Goods or proof of received return delivery.</p>

<p>Contact Us</p>

<p>If you have any questions about our Returns and Refunds Policy, please contact us:</p>

<p>By visiting this page on our website: www.orderfreshlife.com By sending us an email: orderfreshlife@gmail.com</p>
        </div>
    )
}

export default Refund;