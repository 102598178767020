import "./cart.css";
import "./../util.css";
import Orange from "./../icon/orange.png";
import BottomMenu from "./bottomMenu";
import { useEffect, useState, createRef } from "react";
import axios from "axios";
// import Razorpay from 'razorpay'
import { redirect } from "react-router-dom";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Dustbin from "./../icon/delete.png";
import emptycart from './../icon/emptycart1.png'
import Check from './../icon/check11.png'

function Cart(props) {
  const [cart, setCart] = useState([]);
  const [credentials, setCredentials] = useState({});
  const [updateCart, setUpdateCart] = useState(true);
  const [paymentMethodChoosing, setPaymentMEthodChoosing] = useState("");
  const [combo, setcombo] = useState([]);
  const paymentMethod = createRef();
  const [orderConfirming, setOrderConfirming] = useState(true);
  const [priceupdateGardClause, setpriceupdateGardClause] = useState(0)
  const [gettinguserdetails, setUserdetails] = useState({})
  const [addressAdding, setAddressSetting] = useState(false)
  const addresLine = createRef()
  const city = createRef()
  const state = createRef()
  const pincode = createRef()



  useEffect(() => {
    props.loaderSet(true)
    axios({
      method: "POST",
      url: "https://wheelb2cback.onrender.com/api/v1/user/cart-items",
      data : {
        token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout'
      }
    }).then((res) => {
      if (res.data.status === "success") {
        props.loaderSet(false)
        setCart((cart) => res.data.data.cart);
        props.cartCountupdate(res.data.data.cart.length)
        const creds = {
          subtotal: res.data.data.subTotal,
          total: res.data.data.total,
        };
        setCredentials((credentials) => creds);
        setcombo((combo) => res.data.data.combo);
        console.log(res.data.data.combo);
        
      }
    });
  }, [props.updatingCartCount, updateCart, priceupdateGardClause]);


  useEffect(el=>{
    axios({
      method : 'POST',
      url : 'https://wheelb2cback.onrender.com/api/v1/user/sending-user',
      data : {
          token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout'
      }
  }).then(res=>{
      if(res.data.status==='success'){
        setUserdetails(gettinguserdetails=> res.data.data.user)
      }
  })
  }, [addressAdding])


  function update() {
    axios({
      method: "POST",
      url: "https://wheelb2cback.onrender.com/api/v1/user/cart-update",
      data: {},
    });
  }

  const updationGlause = true;


  function choosingPaymentMethod() {
    if (paymentMethod.current.value === "cod") {
      setPaymentMEthodChoosing((paymentMethodChoosing) => "cod");
    }
    if (paymentMethod.current.value === "online") {
      setPaymentMEthodChoosing((paymentMethodChoosing) => "online");
    }
  }

  function onlinePayment() {

    if(credentials.total<100){
      Swal.fire({
        title: "Success!",
        text: `Order must be of at-least 100 ruppes, add ${100-credentials.total} ruppes more to order`,
        icon: "Success",
        confirmButtonText: "close",
      });
      return;
    }

    if(credentials.total>=400){
      Swal.fire({
        title: "Success!",
        text: `Congratulation you get 1Kg free Potato from our side 😍`,
        icon: "Success",
        confirmButtonText: "close",
      });
    }

    axios({
      method: "POST",
      url: "https://wheelb2cback.onrender.com/api/v1/user/online-payment",
      data : {
        token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout'
      }
    }).then((res) => {
      if (res.data.status === "success") {
        console.log('paying')
        var options = {
          key: "rzp_test_7FSn7opeUIrdfi", // Enter the Key ID generated from the Dashboard
          amount: `${credentials.total}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "The Supply Wheel",
          description: "Test Transaction",
          image: "https://example.com/your_logo",
          order_id: `${res.data.data.id}`, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: function (response) {
            axios({
              method: "POST",
              url: "https://wheelb2cback.onrender.com/api/v1/user/payment-validate",
              data: {
                order_id: `${res.data.data.id}`,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
                token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout'
              },
            }).then((res) => {
              if (res.data.status === "success") {
                Swal.fire({
                  title: "Success!",
                  text: res.data.data.message,
                  icon: "Success",
                  confirmButtonText: "close",
                });
                setOrderConfirming((orderConfirming) => false);
              }
            });
          },
          prefill: {
            name: "The Supply Wheel",
            email: "thesupplywheel@gmail.com",
            contact: "9478181139",
          },
          notes: {
            address: "Mandi maqsuda shop number 139 jalandhar punjab",
          },
          theme: {
            color: "#287f71",
          },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
      }
    });
  }

  function CODpaymentAndPlacingOrder() {
    if(credentials.total<100){
      Swal.fire({
        title: "Success!",
        text: `Order must be of at-least 100 ruppes, add ${100-credentials.total} ruppes more to order`,
        icon: "Success",
        confirmButtonText: "close",
      });
      return;
    }

    if(credentials.total>=400){
      Swal.fire({
        title: "Success!",
        text: `Congratulation you get 1Kg free Potato from our side 😍`,
        icon: "Success",
        confirmButtonText: "close",
      });
    }

    
    axios({
      method: "POST",
      url: "https://wheelb2cback.onrender.com/api/v1/user/cod-payment",
      data : {
        token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout'
      }
    }).then((res) => {
      if (res.data.status === "success") {
        Swal.fire({
          title: "Success!",
          text: res.data.data.message,
          icon: "Success",
          confirmButtonText: "close",
        });
        setOrderConfirming((orderConfirming) => false);
      }
    });
  }

  function deleteItemFromCart(el) {
    el.preventDefault();
    axios({
      method: "POST",
      url: "https://wheelb2cback.onrender.com/api/v1/user/delete",
      data: {
        name: el.target.parentNode.parentNode.children[0].children[1]
          .children[0].textContent,
        token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout'
      },
    }).then((res) => {
      if (res.data.status === "success") {
        setCart((cart) => res.data.data.cart);
        props.cartCountupdate(res.data.data.cart.length)
        setpriceupdateGardClause(priceupdateGardClause=> res.data.data.cart.length)
        Swal.fire({
          title: "Success!",
          text: res.data.data.message,
          icon: "Success",
          confirmButtonText: "close",
        });
      }
    });
  }

  function fillingUpAddresAndSavingTobackend(el){
    el.preventDefault()
    setAddressSetting(addressAdding=> true)
  }

  function addressFillingToBackend(el){
    el.preventDefault()
    axios({
      method : 'POST',
      url : 'https://wheelb2cback.onrender.com/api/v1/user/change-address',
      data : {
        token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout',
        address : addresLine.current.value + ' ' + city.current.value + ' ' + state.current.value + ' ' + pincode.current.value
      }
    }).then(res=>{
      if(res.data.status==='success'){
        Swal.fire({
          title: "Success!",
          text: res.data.data.message,
          icon: "Success",
          confirmButtonText: "close",
        });
        setAddressSetting(addressAdding=> false)
      }
    })
  }

  
  function add(el){
    const val = el.target.parentNode.children[1];
    const name = el.target.parentNode.parentNode.children[0].children[1].children[0].textContent;
    if(name === 'kiwi' || name === 'avacado' || name === 'banana' || name === 'dragon' || name === 'pineapple rani' || name === 'raja pineapple' || name === 'sharda' || name === 'papita' || name === 'kharbooja' || name === 'water melon'){
      val.textContent = (Number(val.textContent) + 1).toFixed(1);
    }else if(name === 'red capsicum' || name === 'yellow capsicum' || name === 'allo' || name === 'arbi' || name === 'amla' || name === 'baigan' || name === 'bhindi' || name === 'beans' || name === 'gajar' || name === 'goal giya' || name === 'hara dhaniya' || name === 'hari mirch' || name === 'hara payaz' || name === 'halwa kaddu' || name === 'kheera china' || name === 'karela' || name === 'lokki' || name === 'kheera desi' || name === 'mooli' || name === 'onion' || name === 'patta gobhi' || name === 'pudina' || name === 'phool gobhi' || name === 'palak' || name === 'shimla mirch' || name === 'zimikhand' || name === 'bhe' || name === 'turi' || name === 'tomato'){
      val.textContent = (Number(val.textContent) + 0.25).toFixed(1);
    }else if(name === 'nimbu' || name === 'adrak' || name === 'lasan' || name === 'basil' || name === 'parsely' || name === 'rose merry' || name === 'thym'){
      val.textContent = (Number(val.textContent) + 0.10).toFixed(1);
    }else if(name === 'cherry tomato' || name === 'lettuce' || name === 'cheery' || name === 'baby corn' || name === 'celery' || name === 'pokchey' || name === 'leeks' || name === 'leeks' || name === 'mashroom'){
      val.textContent = (Number(val.textContent) + 0.20).toFixed(1);
    }else if(name === 'frozen mattar' || name === 'beet root' || name === 'orange' || name === 'mausmi' || name === 'mango' || name === 'grapes' || name === 'babbu gosha' || name === 'amrood' || name === 'anar' || name === 'apple' || name === 'red cabbage' || name === 'peeled garlic' || name === 'khatal' || name === 'broccoli' || name === 'china cabbage' || name === 'ice berg' || name === 'zucchini green' || name === 'zucchini yellow'){
      val.textContent = (Number(val.textContent) + 0.50).toFixed(1);
    }else if(name === 'half boiled noodles' || name === 'french fries' || name === 'masala fries' ||  name === 'soya chaap' || name === 'sweet corn' || name === 'frozen vegetables'){
      val.textContent = (Number(val.textContent) + 1).toFixed(1);
    }
    axios({
      method: "POST",
      url: "https://wheelb2cback.onrender.com/api/v1/user/cart-update",
      data: {
        name: name,
        units: val.textContent,
        token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout'
      },
    }).then(res=>{
      if(res.data.status==='success'){
        // setItems(items=> res.data.data.items)
        // setList(list=> res.data.data.items)
        // setgrossPrice(grossPrice=> res.data.data.price)
        setCart((cart) => res.data.data.cart);
        const creds = {
          subtotal: res.data.data.price,
          total: res.data.data.totalPrice,
        };
        setCredentials((credentials) => creds);
        
      }
    })

  }

  function substract(el){
    const val = el.target.parentNode.children[1];
    const name = el.target.parentNode.parentNode.children[0].children[1].children[0].textContent;
    if(name === 'kiwi' || name === 'avacado' || name === 'banana' || name === 'dragon' || name === 'pineapple rani' || name === 'raja pineapple' || name === 'sharda' || name === 'papita' || name === 'kharbooja' || name === 'water melon'){
      val.textContent = (Number(val.textContent) - 1).toFixed(1);
    }else if(name === 'red capsicum' || name === 'yellow capsicum' || name === 'allo' || name === 'arbi' || name === 'amla' || name === 'baigan' || name === 'bhindi' || name === 'beans' || name === 'gajar' || name === 'goal giya' || name === 'hara dhaniya' || name === 'hari mirch' || name === 'hara payaz' || name === 'halwa kaddu' || name === 'kheera china' || name === 'karela' || name === 'lokki' || name === 'kheera desi' || name === 'mooli' || name === 'onion' || name === 'patta gobhi' || name === 'pudina' || name === 'phool gobhi' || name === 'palak' || name === 'shimla mirch' || name === 'zimikhand' || name === 'bhe' || name === 'turi' || name === 'tomato'){
      val.textContent = (Number(val.textContent) - 0.25).toFixed(1);
    }else if(name === 'nimbu' || name === 'adrak' || name === 'lasan' || name === 'basil' || name === 'parsely' || name === 'rose merry' || name === 'thym'){
      val.textContent = (Number(val.textContent) - 0.10).toFixed(1);
    }else if(name === 'cherry tomato' || name === 'lettuce' || name === 'cheery' || name === 'baby corn' || name === 'celery' || name === 'pokchey' || name === 'leeks' || name === 'leeks' || name === 'mashroom'){
      val.textContent = (Number(val.textContent) - 0.20).toFixed(1);
    }else if(name === 'frozen mattar' || name === 'beet root' || name === 'orange' || name === 'mausmi' || name === 'mango' || name === 'grapes' || name === 'babbu gosha' || name === 'amrood' || name === 'anar' || name === 'apple' || name === 'red cabbage' || name === 'peeled garlic' || name === 'khatal' || name === 'broccoli' || name === 'china cabbage' || name === 'ice berg' || name === 'zucchini green' || name === 'zucchini yellow'){
      val.textContent = (Number(val.textContent) - 0.50).toFixed(1);
    }else if(name === 'half boiled noodles' || name === 'french fries' || name === 'masala fries' ||  name === 'soya chaap' || name === 'sweet corn' || name === 'frozen vegetables'){
      val.textContent = (Number(val.textContent) - 1).toFixed(1);
    }
    axios({
      method: "POST",
      url: "https://wheelb2cback.onrender.com/api/v1/user/cart-update",
      data: {
        name: name,
        units: val.textContent,
        token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout'
      },
    }).then(res=>{
      if(res.data.status==='success'){
        // setItems(items=> res.data.data.items)
        // setList(list=> res.data.data.items)
        // setgrossPrice(grossPrice=> res.data.data.price)
        setCart((cart) => res.data.data.cart);
        const creds = {
          subtotal: res.data.data.price,
          total: res.data.data.totalPrice,
        };
        setCredentials((credentials) => creds);
      }
    })

  }

  return (
    <div className="covering">
      {orderConfirming ? (
        <div className="cart flex flex-dir">
          <div className="coverCart flex pad16 flex-dir gap16">
            <h2 id='head' className="carthead">Cart</h2>
            <div className="cartItem flex flex-dir gap16">
              {cart.map((el,index) => (
                <div className="cartProd flex flex-2 pad16 gap8">
                  <div className="flex flex-1 gap16">
                    <div className="countofcart pad8">
                      <p className="countofitems">{index+1}</p>
                    </div>
                    <div className="flex flex-dir gap8">
                      <p className="name">{el.itemName}</p>
                      <p className="name">{el.price}</p>
                      {/* <p className='name'>quanity added - {el.units}kg</p> */}
                    </div>
                  </div>
                  {el.itemName === 'kiwi' || el.itemName === 'avacado' || el.itemName === 'banana' || el.itemName === 'dragon' || el.itemName === 'pineapple rani' || el.itemName === 'raja pineapple' || el.itemName === 'sharda' || el.itemName === 'papita' || el.itemName === 'kharbooja' || el.itemName === 'water melon' ?
                    <div className="countbox pad8 flex flex-1 gap8">
                      <button onClick={substract} className="add">-</button>
                      <p className="count">{el.units}</p>
                      <button onClick={add} className="add">+</button>
                      <p className="unitsInCartupdate">piece</p>
                    </div>
                    :
                    <div className="countbox pad8 flex flex-1 gap8">
                      <button onClick={substract} className="add">-</button>
                      <p className="count">{el.units}</p>
                      <button onClick={add} className="add">+</button>
                      <p className="unitsInCartupdate">kg</p>
                    </div>
                    }
                  <button onClick={deleteItemFromCart} className="deleteBtn">
                    <img src={Dustbin} className="icon__" alt="delete" />
                  </button>
                </div>
              ))}

              {cart.length===0 && 
                <div className="flex flex-dir gap16">
                  <img src={emptycart} alt='empty cart image' className=""/>
                  <p className="emptyCart">Hey! fill your cart just like this</p>
                </div>
              }

              <div className="flex flex-dir gap8">
                {cart.length!==0 &&
                  <>
                    <p className="sub total">Sub total -{(credentials.subtotal).toFixed(1)}/-</p>
                    <p className="sub total">Delivery charges -20/-</p>
                    <p className="sub total">tax - 2/-</p>
                    <p className="sub total">Platform charges - 2/-</p>
                    <p className="sub total">Total - {(credentials.total).toFixed(1)}/-</p>
                  </>
                }
                <hr/>

                {cart.length > 0 && (
                  <>
                    {gettinguserdetails.address &&
                      <div className="addresspicking flex flex-dir gap8">
                        <p className="paymentprocess">Delivery address</p>
                        <p className="addresss">{gettinguserdetails.address}</p>
                      </div>
                    }
                    <hr/>
                    {!gettinguserdetails.address &&
                      <div className="addresspicking flex flex-dir gap8">
                        <p className="paymentprocess">Add delivery address</p>
                        <button onClick={fillingUpAddresAndSavingTobackend} className="border_btn border_btn_fill">Add address</button>                        
                      </div>
                    }
                    
                    {addressAdding &&
                      <form className="addressform flex flex-1 flex-dir gap16">
                        <div className="heading">
                          <h2 className="head">Add address</h2>
                        </div>
                        <input ref={addresLine} className="inp inp_updated" placeholder="Address line*"/>
                        <input ref={city} className="inp inp_updated" placeholder="City*"/>
                        <input ref={state} className="inp inp_updated" placeholder="State*"/>
                        <input ref={pincode} className="inp inp_updated" placeholder="Pincode*"/>
                        <button onClick={addressFillingToBackend} className="border_btn border_btn_fill addressaddbtn">Add address</button>
                      </form>
                    }
                    {gettinguserdetails.address &&
                        <div className="paymentprocessbox flex flex-dir gap8">
                          <p className="paymentprocess">Choose payment method</p>
                          <select
                            onChange={choosingPaymentMethod}
                            ref={paymentMethod}
                            className="inp__update inp"
                            >
                            <option className="opt" value="choose">
                              Choose payment method
                            </option>
                            <option className="opt" value="cod">
                              Cash on delivery
                            </option>
                            <option className="opt" value="online">
                              online payment
                            </option>
                          </select>
                        </div>
                    }

                    {gettinguserdetails.address &&
                      <>
                        {paymentMethodChoosing === "cod" ? (
                          <button
                            onClick={CODpaymentAndPlacingOrder}
                            className="placeorder border_btn border_btn_fill"
                          >
                            Place order with COD
                          </button>
                        ) : (
                          <button
                            onClick={onlinePayment}
                            className="placeorder border_btn border_btn_fill"
                          >
                            Place order with online payment
                          </button>
                        )}
                        <p className="desclaimer">You are accepting out Terms & Conditions on ordering</p>
                      </>
                    }
                  </>
                )}
              </div>
            </div>
            <div className="combos flex flex-dir gap16">
              {combo.map((el, index) => (
                <div className="comb flex flex-dir gap16 pad16">
                  <p className="combocred-">Combo - {index + 1}</p>
                  <div className="grid grid-2-col gap16">
                    {el.list.map(item=>
                        <div className="corentbox flex flex-1 gap16">
                            <p className="combocred">{item.name}</p>
                            <p className="combocred">{item.quantity}kg</p>
                        </div>
                    )}
                  </div>
                    
                </div>
              ))}
            </div>
          </div>
          <div className="bottom">
            <BottomMenu count={props.cartCount} />
          </div>
        </div>
      ) : (
        <div className="placedScreen pad16 flex flex-1 flex-dir gap48">
          <div className="outer flex flex-1 flex-dir gap16">
            <img src={Check} alt="check" className="check"/>
            <p className="placename">Order placed</p>
            <p className="thanks">Thankyou very for choosing us!</p>
          </div>
          <Link to="/placed" className="placedordertoplacedsectionbtn border_btn border_btn_fill">
            See your placed order
          </Link>
        </div>
      )}
    </div>
  );
}

export default Cart;
