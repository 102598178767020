import './App.css';
import './util.css'


import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './components/Home';
import SignUp from './components/signup';
import Login from './components/login';
import Main from './components/main';
import AllProducts from './components/allProducts';
import Cart from './components/cart';
import Fruits from './components/fruits';
import Vegetable from './components/vegetable';
import PlacedOrders from './components/placedOrders';
import History from './components/history';
import Contact from './components/contact';
import Feedback from './components/feedback';
import Setting from './components/setting';
import Forgot from './components/forgot';
import Complain from './components/complain';
import Terms from './components/terms';
import Refund from './components/refund';
import Detail from './components/detail';
import RefundOrders from './components/refund_orders';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, createRef } from 'react';
import close from './icon/close.png'
import axios from 'axios'
import Swal from 'sweetalert2'
import gif from './icon/gif.gif'
import { Link } from 'react-router-dom';

function utilError(data){
  Swal.fire({
    title: "Success!",
    text: data,
    icon: "Success",
    confirmButtonText: "close",
  });
}

function AccountRegisteration(props){
  const [openOTP, setOpenOTP] = useState(false)
  const phone = createRef()
  const otp = createRef()
  const password = createRef()

  const [phoneFilles, setphoneFilled] = useState(0)
  const [passwordFilles, setpasswordFilled] = useState(0)

  const [otpFromBackend, setoptfrombackend] = useState(0)
  const [openRegisterationanel, setopenRegisterationanel] = useState(true)
  
  function OTPpanel(el){
    el.preventDefault()
    if(phone.current.value==='' || phone.current.value.split('').length<10){
      utilError('please entre valid username')
      return;
    }

    axios({
      method : 'POST',
      url : 'http://127.0.0.1:9999/api/v1/user/exist',
      data : {
        phone : phone.current.value
      }
    }).then(res=>{
      if(res.data.status==='success'){
        if(res.data.data.user.length!==0){
          Swal.fire({
            title: "Success!",
            text: 'user already exist with this number, please login',
            icon: "Success",
            confirmButtonText: "close",
          });
          return
        }else{
          setOpenOTP(openOTP=> true)
        }
      }
    })

    setphoneFilled(phoneFilles=> phone.current.value)
    setpasswordFilled(passwordFilles=> password.current.value)
    setoptfrombackend(otpFromBackend=> 4566)

    // axios({
    //   method : "GET",
    //   url : "http://127.0.0.1:5000/code"
    // }).then(res=>{
    //   if(res.data.status==='success'){
    //     setoptfrombackend(otpFromBackend=> res.data.data.otp)
    //   }
    // })
  }

  function verifyingOTPandregisteringUser(el){
    el.preventDefault()
    if(String(otp.current.value) === String(otpFromBackend)){
      axios({
        method : "POST",
        url : 'http://127.0.0.1:9999/api/v1/user/open-acc',
        data : {
          phone : phoneFilles,
          password : passwordFilles
        }
      }).then(res=>{
        if(res.data.status==='success'){
          Swal.fire({
            title: "Success!",
            text: res.data.data.message,
            icon: "Success",
            confirmButtonText: "close",
          });
          localStorage.setItem('b2ctoken', res.data.data.token)
          setopenRegisterationanel(openRegisterationanel=> false)
          props.openingRegisterationPanel(false)
        }
      })
    }
  }
  function closePnael(el){
    setOpenOTP(openOTP=> false)
  }
  function closeRegisterationPanel(){
    setopenRegisterationanel(openRegisterationanel=> false)
    props.openingRegisterationPanel(false)
  }
 
  
  return(
    <div className='register pad16 flex flex-1'>
      {openRegisterationanel &&
      <>
        {!openOTP &&
          <div className='registerbox pad16 flex flex-dir flex-1 gap16'>
            <img onClick={closeRegisterationPanel} src={close} className='icon_close' alt='image'/>
            <h2 className='registerhead'>Register your number</h2>
            <form className='numberform flex flex-dir gap8'>
            <input ref={phone} className='inp' type='text' placeholder='Mobile*'/>
            <input ref={password} className='inp' type='password' placeholder='Password*' />
            <button onClick={OTPpanel} className='veriftyOTP border_btn border_btn_fill'>Verify OTP</button>
            <Link to='/login' className='login' >Login</Link>
          </form>
          </div>
        }
        {openOTP &&
          <div className='registerbox pad16 flex flex-dir flex-1 gap16'>
            <img onClick={closeRegisterationPanel} src={close} className='icon_close' alt='image'/>
            <h2 className='registerhead'>Verify OTP</h2>
            <form className='numberform flex flex-dir gap8'>
            <input ref={otp} className='inp' type='text'placeholder='OTP*'/>
            <button onClick={verifyingOTPandregisteringUser} className='veriftyOTP border_btn border_btn_fill'>Open Account</button>
            </form>
          </div>
        }
      </>
    }


    </div>
  )
}

function App() {

  const [updatingCartCount, setupdatingCartCount] = useState(false)
  const [mainPageSearchImplementation, setMainPageSearchImplementation] = useState('')
  function updatingCartCountfun(cond){
    setupdatingCartCount(updatingCartCount=> cond)
  }

  function NotFound(){
    const navigate = useNavigate()
    useEffect(el=>{
      navigate('/');
    }, [])

    return(
      <div className=''>not found</div>
    )
  }

  const [loader, setLoader] = useState(false)
  const [cartCount, setcartCount] = useState(0)

  const [filterpass, setFilterPass] = useState('')

  function loaderSet(cond){
    setLoader(loader=> cond)
  }

  function filterPassing(cond){
    setFilterPass(filterpass=> cond)
  }

  function cartCountupdate(cond){
    setcartCount(cartCount=> cond)
  }

  function searchingMainPageResultToallProducts(cond){
    console.log(cond)
    setMainPageSearchImplementation(mainPageSearchImplementation=> cond)
  }

  return (
    <div className='app'>
      
      {loader && 
        <div className='loaderbox flex flex-1'>
          <img src={gif} className='gif' alt='gif'/>
        </div>
      }
      <BrowserRouter>
        <Routes>
          {/* <Route path='' element={<Home/>}/> */}
          <Route path='open-acc' element={<SignUp loaderSet={loaderSet} />}/>
          <Route path='login' element={<Login loaderSet={loaderSet} />}/>
          <Route path='/' element={<Main searchingMainPageResultToallProducts={searchingMainPageResultToallProducts} AccountRegisteration={AccountRegisteration} filterPassing={filterPassing} />}/>
          <Route path='all-products' element={<AllProducts mainPageSearchImplementation={mainPageSearchImplementation} AccountRegisteration={AccountRegisteration} cartCount={cartCount} cartCountupdate={cartCountupdate} loaderSet={loaderSet} filterpass={filterpass} updatingCartCountfun={updatingCartCountfun} />}/>
          <Route path='cart' element={<Cart cartCount={cartCount} cartCountupdate={cartCountupdate} loaderSet={loaderSet} updatingCartCount={updatingCartCount} />}/>
          <Route path='fruits' element={<Fruits/>}/>
          <Route path='vegetables' element={<Vegetable/>}/>
          <Route path='placed' element={<PlacedOrders loaderSet={loaderSet}/>}>

          </Route>
          <Route path='refund-orders' element={<RefundOrders/>}/>

          <Route path='history' element={<History loaderSet={loaderSet} />}/>
          <Route path='contact' element={<Contact loaderSet={loaderSet} />}/>
          <Route path='feedback' element={<Feedback loaderSet={loaderSet} />}/>
          <Route path='setting' element={<Setting loaderSet={loaderSet} />}/>
          <Route path='forgot' element={<Forgot loaderSet={loaderSet} />}/>
          <Route path='complain' element={<Complain loaderSet={loaderSet} />}/>
          <Route path='terms-conditions' element={<Terms loaderSet={loaderSet} />}/>
          <Route path='refund' element={<Refund loaderSet={loaderSet} />}/>
          <Route path='detail/:id' element={<Detail/>}/>
          <Route path='*' element={<NotFound loaderSet={loaderSet} />}/>
        
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
