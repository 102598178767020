import "./../util.css";
import "./main.css";
import Menu from "./../icon/menu.png";
import BottomMenu from "./bottomMenu";
import Carrot from "./../icon/carrot.png";
import Cado from "./../icon/cado.png";
import Close from "./../icon/close.png";
import Home from "./../icon/home.png";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { useState, useEffect, createRef } from "react";
import Frame1 from "./../icon/Frame1.png";
import Pro from "./../icon/pro1.png";
import Pro2 from "./../icon/pro2.png";
import Combo from "./../icon/combo.jpg";
import allo from './../icon/potato.png'
import Ginger from './../icon/ginger.png'
import Taro from './../icon/taro.png'
import Amla from './../icon/amla.png'
import Baigan from './../icon/eggplant.png'
import Bhindi from './../icon/bhindi.png'
import Beans from './../icon/green-beans.png'
import Golgiya from './../icon/golgiya.png'
import Dhaniya from './../icon/coriander.png'
import GreenChili from './../icon/green-chilli.png'
import GreenOnion from './../icon/green-onion.png'
import Halwa from './../icon/halwa.png'
import Cocumber from './../icon/cucumber.png'
import Karela from './../icon/karela.png'
import Lokki from './../icon/long.png'
import Garlic from './../icon/garlic.png'
import desiKheera from './../icon/desikheeraa.png'
import Radish from './../icon/radish.png'
import Lemon from './../icon/lemon.png'
import Onion from './../icon/onion.png'
import Cabbage from './../icon/cabbage.png'
import Mint from './../icon/spice.png'
import Phool from './../icon/cauliflower.png'
import Spinach from './../icon/spinach.png'
import Shimla from './../icon/shimla.png'
import Lotus from './../icon/lotus.png'
import Jack from './../icon/jack.png'
import turi from './../icon/farm.png'
import tomato from './../icon/tomato.png'
import corn from './../icon/corn.png'
import basil from './../icon/basil.png'
import broccoli from './../icon/broccoli.png'
import cherry from './../icon/cherries.png'
import celery from './../icon/celery.png'
import lettuce from './../icon/lettuce.png'
import lettuce1 from './../icon/lettuce1.png'
import parsley from './../icon/parsley.png'
import peel from './../icon/peel.png'
import bellr from './../icon/bellr.png'
import belly from './../icon/belly.png'
import redcab from './../icon/red-cabbage.png'
import zucg from './../icon/zucchini.png'
import zucy from './../icon/zucchiniy.png'
import Apple from './../icon/apple.png'
import anar from './../icon/anar.png'
import guava from './../icon/guava.png'
import kela from './../icon/banana.png'
import grapes from './../icon/grapes.png'
import melon from './../icon/melon.png'
import kiwi from './../icon/kiwi.png'
import mango from './../icon/mango.png'
import Orange from './../icon/orange.png'
import pine from './../icon/pineapple.png'
import papita from './../icon/papaya.png'
import beet from './../icon/beet.png'
import noimg from './../icon/no-pictures.png'
import watermelon from './../icon/watermelon.png'
import { useNavigate } from 'react-router-dom';
import AllFruit from './../icon/allfruit.png'
import AllVeg from './../icon/allveg.webp'
import AllFrz from './../icon/allfrozen.png'
import phone from './../icon/telephone.png'

import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';

const slides = [
  { title: 'First item', description: 'Lorem ipsum'},
  { title: 'Second item', description: 'Lorem ipsum'}
];

function Main(props) {
  const navigate = useNavigate();
  const [sidebarStatus, setSideBarStatus] = useState(true);
  const [existingCartTomain, setExistingCarttoMain] = useState([]);
  const [fruits, setFruits] = useState([]);
  const [vegetables, setVegetables] = useState([]);
  const [allStuff, setAllStuff] = useState([])
  const [english, setEnglish] = useState([]);
  const [frozen, setFrozen] = useState([]);
  const [comboOffer, setComboOffer] = useState([]);
  const [comboUnits, setComboUnits] = useState('');
  const [loggingout, setlogout] = useState(false)
  const [allProductsFromBackend, setAllProductsFromBackend] = useState([])

  const [adrak] = useState("https://orderfreshlife.blob.core.windows.net/vegies/adrak.webp?sp=r&st=2024-03-16T22:46:24Z&se=2025-03-17T06:46:24Z&spr=https&sv=2022-11-02&sr=b&sig=YzmbtWwsoeMvZyKLZ%2BGJ73koqCKhrmLJAXUMHbku5Gg%3D")
  const [amla] = useState("https://orderfreshlife.blob.core.windows.net/vegies/amla.webp?sp=r&st=2024-03-16T22:47:30Z&se=2025-03-17T06:47:30Z&spr=https&sv=2022-11-02&sr=b&sig=Cgmmqo82qW57d8%2B%2F9xNLpSRqbrECMkN9m8hQ%2B0VHSRE%3D")
  const [apple] = useState("https://orderfreshlife.blob.core.windows.net/vegies/apple.webp?sp=r&st=2024-03-16T22:47:53Z&se=2025-03-17T06:47:53Z&spr=https&sv=2022-11-02&sr=b&sig=ZH%2FbQeh8xOFLG4ad2IHgruy%2F7fc7C%2Bw92L2u1qRw6mY%3D")
  const [arbi] = useState("https://orderfreshlife.blob.core.windows.net/vegies/arbi.webp?sp=r&st=2024-03-16T22:48:08Z&se=2025-03-17T06:48:08Z&spr=https&sv=2022-11-02&sr=b&sig=23uhTka8UIQPHCj3UvM3Alz%2B5wsGtlgHH8ZZTj7fsKY%3D")
  const [babbu] = useState("https://orderfreshlife.blob.core.windows.net/vegies/babbugosha.webp?sp=r&st=2024-03-16T22:48:36Z&se=2025-03-17T06:48:36Z&spr=https&sv=2022-11-02&sr=b&sig=ck3Q2RXOZYRTIXIzGSq2r%2F6J0QPxbFYnqoEwOhAVYkg%3D")
  const [corn] = useState("https://orderfreshlife.blob.core.windows.net/vegies/babycorn.webp?sp=r&st=2024-03-16T22:49:05Z&se=2025-03-17T06:49:05Z&spr=https&sv=2022-11-02&sr=b&sig=x%2FWL2UnUK80Sba6cg5%2B10mKmlCpOcbfaoF8qZ0HPQ9o%3D")
  const [baigan] = useState("https://orderfreshlife.blob.core.windows.net/vegies/baigan.webp?sp=r&st=2024-03-16T22:49:24Z&se=2025-03-17T06:49:24Z&spr=https&sv=2022-11-02&sr=b&sig=gfYCEP7AJZMCONBunUGvqC04GCyy%2Fw21ORT4b%2BqlYtA%3D")
  const [kela] = useState("https://orderfreshlife.blob.core.windows.net/vegies/banana.webp?sp=r&st=2024-03-16T22:49:42Z&se=2025-03-17T06:49:42Z&spr=https&sv=2022-11-02&sr=b&sig=AfemvMi79SLfyX0guKBix%2BhYROwzv7p0g%2B2LFAnIXmQ%3D")
  const [bandgobi] = useState("https://orderfreshlife.blob.core.windows.net/vegies/band%20ghobi.webp?sp=r&st=2024-03-16T22:49:59Z&se=2025-03-17T06:49:59Z&spr=https&sv=2022-11-02&sr=b&sig=P9R5cNawXO8LM%2B3uCXsBA9HZ%2FdVGXdZwMPQLolWSGmk%3D")
  const [basil] = useState("https://orderfreshlife.blob.core.windows.net/vegies/basil.webp?sp=r&st=2024-03-16T22:50:25Z&se=2025-03-17T06:50:25Z&spr=https&sv=2022-11-02&sr=b&sig=Mr0lX9dgjPBEiBlLmiOy28lOKvWfKSVxCJ4UYYKU%2F3w%3D")
  const [beans] = useState("https://orderfreshlife.blob.core.windows.net/vegies/beans.webp?sp=r&st=2024-03-16T22:50:42Z&se=2025-03-17T06:50:42Z&spr=https&sv=2022-11-02&sr=b&sig=9Vtrbxdz%2FpGbLLKQWb6cMEH4K0MwoJH3%2B2AbLw8oF4k%3D")
  const [beetroot] = useState("https://orderfreshlife.blob.core.windows.net/vegies/beans.webp?sp=r&st=2024-03-16T22:50:42Z&se=2025-03-17T06:50:42Z&spr=https&sv=2022-11-02&sr=b&sig=9Vtrbxdz%2FpGbLLKQWb6cMEH4K0MwoJH3%2B2AbLw8oF4k%3D")
  const [bhe] = useState("https://orderfreshlife.blob.core.windows.net/vegies/bhe.webp?sp=r&st=2024-03-16T22:51:23Z&se=2025-03-17T06:51:23Z&spr=https&sv=2022-11-02&sr=b&sig=7bNIv5AGSUWEQWTFLqjpmhum%2F8Y0z1YOkpFzRBF%2F%2BjI%3D")
  const [bhindi] = useState("https://orderfreshlife.blob.core.windows.net/vegies/bhindi.webp?sp=r&st=2024-03-16T22:51:39Z&se=2025-03-17T06:51:39Z&spr=https&sv=2022-11-02&sr=b&sig=SZAHXYzunnyfdFEO5iD27iOuZBPBYF95tQxEwPrqGBA%3D")
  const [bluegrape] = useState("https://orderfreshlife.blob.core.windows.net/vegies/blue%20graphes.webp?sp=r&st=2024-03-16T22:51:50Z&se=2025-03-17T06:51:50Z&spr=https&sv=2022-11-02&sr=b&sig=V75eWh9QoOp7EWUaER9495hFqLnIJWf6FO2X9aYuE0w%3D")
  const [broccli] = useState("https://orderfreshlife.blob.core.windows.net/vegies/broccoli-florets.jpg.webp?sp=r&st=2024-03-16T22:52:02Z&se=2025-03-17T06:52:02Z&spr=https&sv=2022-11-02&sr=b&sig=FcCL0KnxL6uwJEH4a7qiHsX4XW7bFi64eC2nrNR5Wuw%3D")
  const [cocbrown] = useState("https://orderfreshlife.blob.core.windows.net/vegies/brown%20coconut.webp?sp=r&st=2024-03-16T22:52:17Z&se=2025-03-17T06:52:17Z&spr=https&sv=2022-11-02&sr=b&sig=vRw0Lmk5dx9VcXIZFthsW%2FMzD951pSGmeSBjBUL9cBM%3D")
  const [carrot] = useState("https://orderfreshlife.blob.core.windows.net/vegies/carrot.webp?sp=r&st=2024-03-16T22:52:33Z&se=2025-03-17T06:52:33Z&spr=https&sv=2022-11-02&sr=b&sig=b4KFdKuP4oDWk1zfsZ1YUnsyMpwLI9rSOxiFsI8fSu0%3D")
  const [celery] = useState("https://orderfreshlife.blob.core.windows.net/vegies/celery.webp?sp=r&st=2024-03-16T22:52:49Z&se=2025-03-17T06:52:49Z&spr=https&sv=2022-11-02&sr=b&sig=0fm%2BDBh3HYSaOZZ4yS%2BsqR%2BuqNzPSQAAMOxCvK5qkUI%3D")
  const [cherryTomato] = useState("https://orderfreshlife.blob.core.windows.net/vegies/cherrytomato.webp?sp=r&st=2024-03-16T22:53:03Z&se=2025-03-17T06:53:03Z&spr=https&sv=2022-11-02&sr=b&sig=toqJUNkpCu8BFJ9yf96RTdvLwnCpW9V1LMaaL9xTEvQ%3D")
  const [chikko] = useState("https://orderfreshlife.blob.core.windows.net/vegies/chiku.webp?sp=r&st=2024-03-16T22:53:26Z&se=2025-03-17T06:53:26Z&spr=https&sv=2022-11-02&sr=b&sig=7ZUPyA8mr2UZKvE%2Ftg2Ed14l%2Bq6ETTWc2rhzD9kCKxI%3D")
  const [chinaCabbage] = useState("https://orderfreshlife.blob.core.windows.net/vegies/china%20cabbage.webp?sp=r&st=2024-03-16T22:53:42Z&se=2025-03-17T06:53:42Z&spr=https&sv=2022-11-02&sr=b&sig=ii3kZbuT%2FBysC5Unn2DZEtL2gnLHVxrAD1zWacClqCo%3D")
  const [dhaina] = useState("https://orderfreshlife.blob.core.windows.net/vegies/dhania.webp?sp=r&st=2024-03-16T22:54:20Z&se=2025-03-17T06:54:20Z&spr=https&sv=2022-11-02&sr=b&sig=FA7CNRIp5R350mckZm414cjz%2FhU8Qqm91WtvDGt1A8I%3D")
  const [dragon] = useState("https://orderfreshlife.blob.core.windows.net/vegies/Dragonfruit-White.jpg.webp?sp=r&st=2024-03-16T22:54:33Z&se=2025-03-17T06:54:33Z&spr=https&sv=2022-11-02&sr=b&sig=prQkf6R7erA1gzUv8i97afteDBfPwAhTvq8s0ct%2B2Pc%3D")
  const [fries] = useState("https://orderfreshlife.blob.core.windows.net/vegies/frozen%20french%20fries.jpeg.webp?sp=r&st=2024-03-16T22:55:26Z&se=2025-03-17T06:55:26Z&spr=https&sv=2022-11-02&sr=b&sig=nc%2BD%2FWwWcbOKNPFJ65GVWFeoNRsylVtGYJf9gPnaNgg%3D")
  const [noodles] = useState("https://orderfreshlife.blob.core.windows.net/vegies/frozen%20noodles.webp?sp=r&st=2024-03-16T22:55:39Z&se=2025-03-17T06:55:39Z&spr=https&sv=2022-11-02&sr=b&sig=WWo9Je4ZlSKacPIpJYK%2FIzRXwVD18PlaosF948hlijM%3D")
  const [kiwi] = useState("https://orderfreshlife.blob.core.windows.net/vegies/Kiwi-fruit.jpg.webp?sp=r&st=2024-03-16T22:56:03Z&se=2025-03-17T06:56:03Z&spr=https&sv=2022-11-02&sr=b&sig=eGDkTR2A%2B3%2Baq7Rq17Ue2IMlziEbnTSKwRdkUGNJ%2Fzw%3D")
  const [raniPine] = useState("https://orderfreshlife.blob.core.windows.net/vegies/Rani%20Pineapple_600x%402x.jpg.webp?sp=r&st=2024-03-16T22:56:15Z&se=2025-03-17T06:56:15Z&spr=https&sv=2022-11-02&sr=b&sig=0jMKT%2FBvBAOUv82qOAtmvwUK%2FhpeD2uw4bX14SDu2T0%3D")
  const [redcap] = useState("https://orderfreshlife.blob.core.windows.net/vegies/Red_Capsicum.jpg.webp?sp=r&st=2024-03-16T22:56:33Z&se=2025-03-17T06:56:33Z&spr=https&sv=2022-11-02&sr=b&sig=qAxYSz%2BvbRMngm%2FKgDz8x1O0AguHZA4XrtGD3vFmf3k%3D")
  const [frozenMattar] = useState("https://orderfreshlife.blob.core.windows.net/vegies/frozern%20mattar.webp?sp=r&st=2024-03-16T22:56:51Z&se=2025-03-17T06:56:51Z&spr=https&sv=2022-11-02&sr=b&sig=s65%2BMV7ZnIkvhfybt274C0Im7PmZss24aMH0UMEfN2Y%3D")
  const [fulgobi] = useState("https://orderfreshlife.blob.core.windows.net/vegies/fulgobhi.webp?sp=r&st=2024-03-16T22:57:04Z&se=2025-03-17T06:57:04Z&spr=https&sv=2022-11-02&sr=b&sig=QNnJS7%2BXate9VFezCLHycaTJdJ7J1RzNGuvNjN%2FUN2U%3D")
  const [longkaddu] = useState("https://orderfreshlife.blob.core.windows.net/vegies/ghiya.webp?sp=r&st=2024-03-16T22:57:38Z&se=2025-03-17T06:57:38Z&spr=https&sv=2022-11-02&sr=b&sig=GLlanPTpnMnAGRffDEFODvr2CVIM28tORxqc%2FDqVpyY%3D")
  const [golkaddu] = useState("https://orderfreshlife.blob.core.windows.net/vegies/gol%20kaddu.webp?sp=r&st=2024-03-16T22:57:52Z&se=2025-03-17T06:57:52Z&spr=https&sv=2022-11-02&sr=b&sig=uq2oc8nU6Jdo2AnQaVi9p7O8N56wsFQbCwiOpu%2BLeM4%3D")
  const [greenApple] = useState("https://orderfreshlife.blob.core.windows.net/vegies/green%20apple.jpg.webp?sp=r&st=2024-03-16T22:58:45Z&se=2025-03-17T06:58:45Z&spr=https&sv=2022-11-02&sr=b&sig=NFKcnr3Tj8m6UCjyP%2FSDUb5H6PBJjid2F5HlKOSFUTU%3D")
  const [greencoco] = useState("https://orderfreshlife.blob.core.windows.net/vegies/green%20cocnut.webp?sp=r&st=2024-03-16T22:58:57Z&se=2025-03-17T06:58:57Z&spr=https&sv=2022-11-02&sr=b&sig=n3qBbYh1dbe8Sp1GVYGkh47GGcGC7TTHtqo2X6B9XjQ%3D")
  const [grapes] = useState("https://orderfreshlife.blob.core.windows.net/vegies/green%20graphes.webp?sp=r&st=2024-03-16T22:59:09Z&se=2025-03-17T06:59:09Z&spr=https&sv=2022-11-02&sr=b&sig=FYqN8KmJSCS0%2FVhecKYEuCqagzDfbDU%2BSaEedMC3PcA%3D")
  const [greenOnion] = useState("https://orderfreshlife.blob.core.windows.net/vegies/green%20onion.webp?sp=r&st=2024-03-16T23:00:12Z&se=2025-03-17T07:00:12Z&spr=https&sv=2022-11-02&sr=b&sig=AfL%2FE%2B4lkJJ2Q24ClVG6BH6aHdD8b3LYyqd2LNqvViQ%3D")
  const [amrood] = useState("https://orderfreshlife.blob.core.windows.net/vegies/guava.webp?sp=r&st=2024-03-16T23:00:27Z&se=2025-03-17T07:00:27Z&spr=https&sv=2022-11-02&sr=b&sig=Q1FpJmpn0KWq%2Fe9x1pCfSVDIQ45QWYW4p9ql1JSgJaQ%3D")
  const [greenChilli] = useState("https://orderfreshlife.blob.core.windows.net/vegies/guava.webp?sp=r&st=2024-03-16T23:00:27Z&se=2025-03-17T07:00:27Z&spr=https&sv=2022-11-02&sr=b&sig=Q1FpJmpn0KWq%2Fe9x1pCfSVDIQ45QWYW4p9ql1JSgJaQ%3D")
  const [iceberg] = useState("https://orderfreshlife.blob.core.windows.net/vegies/iceberg.webp?sp=r&st=2024-03-16T23:02:04Z&se=2025-03-17T07:02:04Z&spr=https&sv=2022-11-02&sr=b&sig=TheyqzLVUFBwpSowsQSHa2eeDmi0GYXJZ9rnVUc2gZo%3D")
  const [jack] = useState("https://orderfreshlife.blob.core.windows.net/vegies/jackfruit.webp?sp=r&st=2024-03-16T23:02:21Z&se=2025-03-17T07:02:21Z&spr=https&sv=2022-11-02&sr=b&sig=JyHURH524FMOJEQJi%2F9c4xJYjB1DOEafbwUShdbtJCE%3D")
  const [jimi] = useState("https://orderfreshlife.blob.core.windows.net/vegies/jimikand.webp?sp=r&st=2024-03-16T23:02:35Z&se=2025-03-17T07:02:35Z&spr=https&sv=2022-11-02&sr=b&sig=vVz7UwGCZByFGBpQWaBYd%2F%2Ftym8XDg%2FzYCZc%2BwyqJvs%3D")
  const [karela] = useState("https://orderfreshlife.blob.core.windows.net/vegies/karela.webp?sp=r&st=2024-03-16T23:02:49Z&se=2025-03-17T07:02:49Z&spr=https&sv=2022-11-02&sr=b&sig=DOTlKHlHsr%2BcettOX6jdYPiKT6HBT6MG%2Bl8x42kmIdg%3D")
  const [khajoor] = useState("https://orderfreshlife.blob.core.windows.net/vegies/khajoor.jpg.webp?sp=r&st=2024-03-16T23:03:05Z&se=2025-03-17T07:03:05Z&spr=https&sv=2022-11-02&sr=b&sig=L5S1wxbcugPk4K1J76vwuGz2ecDKFeo6Wd%2BDo4BgoRM%3D")
  const [khera] = useState("https://orderfreshlife.blob.core.windows.net/vegies/khira.webp?sp=r&st=2024-03-16T23:03:24Z&se=2025-03-17T07:03:24Z&spr=https&sv=2022-11-02&sr=b&sig=nA23O0MFSwYd%2FgDFUg7Wvdt8xPMRyQGtzZihvxeZNzg%3D")
  const [kinnu] = useState("https://orderfreshlife.blob.core.windows.net/vegies/kinu.webp?sp=r&st=2024-03-16T23:03:37Z&se=2025-03-17T07:03:37Z&spr=https&sv=2022-11-02&sr=b&sig=rox0a%2F8ofvHcL6t3eBoOO5ccRV2E%2BCoD5KHbb0d2h4Q%3D")
  const [lasan] = useState("https://orderfreshlife.blob.core.windows.net/vegies/lassan.webp?sp=r&st=2024-03-16T23:03:52Z&se=2025-03-17T07:03:52Z&spr=https&sv=2022-11-02&sr=b&sig=9wweI9c8RNilcMXIdxssupbLvRW7K%2FZT2AT5ddbFaRk%3D")
  const [lichi] = useState("https://orderfreshlife.blob.core.windows.net/vegies/lichi.jpg.webp?sp=r&st=2024-03-16T23:04:21Z&se=2025-03-17T07:04:21Z&spr=https&sv=2022-11-02&sr=b&sig=iLJSPzjPK0o%2BmmXNYoVlvO%2FKxBCgudt6rPj%2BMA0p3R4%3D")
  const [mango] = useState("https://orderfreshlife.blob.core.windows.net/vegies/mango.webp?sp=r&st=2024-03-16T23:04:35Z&se=2025-03-17T07:04:35Z&spr=https&sv=2022-11-02&sr=b&sig=zz8eYIT1VPOniXNCP4y2ggd4nfkZ4YmHRiVSSzns9fk%3D")
  const [mattar] = useState("https://orderfreshlife.blob.core.windows.net/vegies/mattar.webp?sp=r&st=2024-03-16T23:05:05Z&se=2025-03-17T07:05:05Z&spr=https&sv=2022-11-02&sr=b&sig=6e8q7luaaIlmdrgRnIkNvRzOw%2BNl0WyXiyY9J4TsEDE%3D")
  const [mint] = useState("https://orderfreshlife.blob.core.windows.net/vegies/mint.webp?sp=r&st=2024-03-16T23:05:25Z&se=2025-03-17T07:05:25Z&spr=https&sv=2022-11-02&sr=b&sig=H3o3WRLXUABwamyPmHOY8nPma0O%2B7FHwleiU7X6P5Bg%3D")
  const [mooli] = useState("https://orderfreshlife.blob.core.windows.net/vegies/mooli.webp?sp=r&st=2024-03-16T23:05:42Z&se=2025-03-17T07:05:42Z&spr=https&sv=2022-11-02&sr=b&sig=NiNONcjckAuG2Nm%2BasBMQqqlVvFBJUOgTLEd11yP6Tk%3D")
  const [mousmi] = useState("https://orderfreshlife.blob.core.windows.net/vegies/mosambi-1-kg-product-images-o590001673-p590001673-0-202203150518.jpg.webp?sp=r&st=2024-03-16T23:05:55Z&se=2025-03-17T07:05:55Z&spr=https&sv=2022-11-02&sr=b&sig=lSKyypTJXCabiQjyY2S9a0W%2FrT1eHRVW6NeBUVPqdDY%3D")
  const [mashroom] = useState("https://orderfreshlife.blob.core.windows.net/vegies/mushroom.webp?sp=r&st=2024-03-16T23:06:17Z&se=2025-03-17T07:06:17Z&spr=https&sv=2022-11-02&sr=b&sig=9L1Hofw1SiCk6E2tGNSkf%2Bd%2BIO83FauVzPAHRw%2FEaWQ%3D")
  const [kerbooja] = useState("https://orderfreshlife.blob.core.windows.net/vegies/muskmelon.webp?sp=r&st=2024-03-16T23:07:04Z&se=2025-03-17T07:07:04Z&spr=https&sv=2022-11-02&sr=b&sig=CxxC7J3%2BR682d3T7KnJNpyPms6VX9pjEOy04%2Fm0di%2BE%3D")
  const [nimbu] = useState("https://orderfreshlife.blob.core.windows.net/vegies/nimbu.webp?sp=r&st=2024-03-16T23:07:24Z&se=2025-03-17T07:07:24Z&spr=https&sv=2022-11-02&sr=b&sig=fWPSBMaQNKdcqi7dRKA39v4sE0eZTDKm%2FzZeXIfMgEo%3D")
  const [onion] = useState("https://orderfreshlife.blob.core.windows.net/vegies/onion.webp?sp=r&st=2024-03-16T23:07:42Z&se=2025-03-17T07:07:42Z&spr=https&sv=2022-11-02&sr=b&sig=GSIvVpkfcsNtxeMzh24LpieO2FG9XU6HKAYv3JlY4Gs%3D")
  const [orange] = useState("https://orderfreshlife.blob.core.windows.net/vegies/oragne.webp?sp=r&st=2024-03-16T23:07:57Z&se=2025-03-17T07:07:57Z&spr=https&sv=2022-11-02&sr=b&sig=nsTfAlyGXGM9mhch0d9%2FVB5wMjYfKZUl8t%2BjO%2FjYi5o%3D")
  const [palak] = useState("https://orderfreshlife.blob.core.windows.net/vegies/palak.webp?sp=r&st=2024-03-16T23:08:13Z&se=2025-03-17T07:08:13Z&spr=https&sv=2022-11-02&sr=b&sig=5e%2BVqgc7o45NhxIXD8%2F7NawLjIWkhFUmyuEnNuP5EiE%3D")
  const [papita] = useState("https://orderfreshlife.blob.core.windows.net/vegies/papita.webp?sp=r&st=2024-03-16T23:08:36Z&se=2025-03-17T07:08:36Z&spr=https&sv=2022-11-02&sr=b&sig=mT3i2Mbq2U7vZp6mnAesTkecrgiVAGHt2UUMrG2gSiI%3D")
  const [parsley] = useState("https://orderfreshlife.blob.core.windows.net/vegies/parsely.webp?sp=r&st=2024-03-16T23:08:48Z&se=2025-03-17T07:08:48Z&spr=https&sv=2022-11-02&sr=b&sig=ncjp8EwTwa8rtHFse2iazNJG6LMEsbJuBO322iS5Atc%3D")
  const [peellasan] = useState("https://orderfreshlife.blob.core.windows.net/vegies/peeled%20lassun.webp?sp=r&st=2024-03-16T23:09:00Z&se=2025-03-17T07:09:00Z&spr=https&sv=2022-11-02&sr=b&sig=WXGSKOSdon0u7nEcNnHVRZStVRjhG%2F40g9Wdi%2FViUec%3D")
  const [ockchey] = useState("https://orderfreshlife.blob.core.windows.net/vegies/pockehy%20bockhey.jpg.webp?sp=r&st=2024-03-16T23:09:12Z&se=2025-03-17T07:09:12Z&spr=https&sv=2022-11-02&sr=b&sig=dOf6heAe6wL9Z5k9yES6pG6K2pr924FT5q108Rp3Pqw%3D")
  const [anar] = useState("https://orderfreshlife.blob.core.windows.net/vegies/pomegernate.webp?sp=r&st=2024-03-16T23:09:25Z&se=2025-03-17T07:09:25Z&spr=https&sv=2022-11-02&sr=b&sig=fwyQSkoxzJ6u4FiTT94C6qloGT5XDwOX%2F1FBSr622Gc%3D")
  const [patato] = useState("https://orderfreshlife.blob.core.windows.net/vegies/potato.webp?sp=r&st=2024-03-16T23:09:36Z&se=2025-03-17T07:09:36Z&spr=https&sv=2022-11-02&sr=b&sig=NohiZm2ENvAhnr1nNm66gQUyAQziLijmgj2Ih489aBs%3D")
  const [rapsberry] = useState("https://orderfreshlife.blob.core.windows.net/vegies/rapsberry.webp?sp=r&st=2024-03-16T23:09:49Z&se=2025-03-17T07:09:49Z&spr=https&sv=2022-11-02&sr=b&sig=X4up7UnE8ijpVa8w46rS2tqEY6tWc3ykfkghnTiRvTs%3D")
  const [rawcado] = useState("https://orderfreshlife.blob.core.windows.net/vegies/raw%20avacado.jpg.webp?sp=r&st=2024-03-16T23:10:03Z&se=2025-03-17T07:10:03Z&spr=https&sv=2022-11-02&sr=b&sig=Aw1Oo0CUhqEcZbb7MApKEPlaUc9xdrdj9ZYyE1lWMO0%3D")
  const [rawpine] = useState("https://orderfreshlife.blob.core.windows.net/vegies/rawpineapple.webp?sp=r&st=2024-03-16T23:10:30Z&se=2025-03-17T07:10:30Z&spr=https&sv=2022-11-02&sr=b&sig=KJc8G2%2BM%2BOuJuRB%2B7hMwjqqMWY9Rx6szZjjWUpYTNRk%3D")
  const [redcabbage] = useState("https://orderfreshlife.blob.core.windows.net/vegies/redcabbage.webp?sp=r&st=2024-03-16T23:10:43Z&se=2025-03-17T07:10:43Z&spr=https&sv=2022-11-02&sr=b&sig=%2BwFHAdPXaduSOlad1OhvMaihWHXKE0Yncl6N98G7XW4%3D")
  const [ripcado] = useState("https://orderfreshlife.blob.core.windows.net/vegies/ripen%20avacado.webp?sp=r&st=2024-03-16T23:10:59Z&se=2025-03-17T07:10:59Z&spr=https&sv=2022-11-02&sr=b&sig=ewFbsYWWkxAGvzO0zpZFtM%2B2p98oABqETzCzGBXo3Fo%3D")
  const [saag] = useState("https://orderfreshlife.blob.core.windows.net/vegies/sarsokasaag.webp?sp=r&st=2024-03-16T23:11:11Z&se=2025-03-17T07:11:11Z&spr=https&sv=2022-11-02&sr=b&sig=PPWjaRePtx6LSmYWDAXbFRqYhrNJm7xPxu5lOhQFU4w%3D")
  const [shalgam] = useState("https://orderfreshlife.blob.core.windows.net/vegies/sarsokasaag.webp?sp=r&st=2024-03-16T23:11:11Z&se=2025-03-17T07:11:11Z&spr=https&sv=2022-11-02&sr=b&sig=PPWjaRePtx6LSmYWDAXbFRqYhrNJm7xPxu5lOhQFU4w%3D")
  const [sharda] = useState("https://orderfreshlife.blob.core.windows.net/vegies/shardhafruit.webp?sp=r&st=2024-03-16T23:11:36Z&se=2025-03-17T07:11:36Z&spr=https&sv=2022-11-02&sr=b&sig=7KrUeY9hibJcd9FiNVbhgJVdWjzlHnmpJChBB16ag24%3D")
  const [shimla] = useState("https://orderfreshlife.blob.core.windows.net/vegies/shimla.webp?sp=r&st=2024-03-16T23:11:48Z&se=2025-03-17T07:11:48Z&spr=https&sv=2022-11-02&sr=b&sig=37fIV1YLcyzj9SUA%2BRe7eEORalgtbf0yl5wh4tp%2Fvs0%3D")
  const [sitta] = useState("https://orderfreshlife.blob.core.windows.net/vegies/sitafal.webp?sp=r&st=2024-03-16T23:12:01Z&se=2025-03-17T07:12:01Z&spr=https&sv=2022-11-02&sr=b&sig=dUYXfX3uzTcCrs4AGl3F6C3C51DwK7sdIhy9OihdvEo%3D")
  const [soyachaap] = useState("https://orderfreshlife.blob.core.windows.net/vegies/soya%20chaap.webp?sp=r&st=2024-03-16T23:12:20Z&se=2025-03-17T07:12:20Z&spr=https&sv=2022-11-02&sr=b&sig=%2BINvdfF1C1Gmo7nPGEepgoi36M%2Bkhd6ROZVtTe%2FHt40%3D")
  const [soyapanner] = useState("https://orderfreshlife.blob.core.windows.net/vegies/soya%20paneer.jpg.webp?sp=r&st=2024-03-16T23:12:32Z&se=2025-03-17T07:12:32Z&spr=https&sv=2022-11-02&sr=b&sig=tz%2BiheaH7bJu39qffdYWX2lDLdQUEsa5mRgA%2BFak3WM%3D")
  const [strawberry] = useState("https://orderfreshlife.blob.core.windows.net/vegies/strawberry.webp?sp=r&st=2024-03-16T23:12:47Z&se=2025-03-17T07:12:47Z&spr=https&sv=2022-11-02&sr=b&sig=ewKzvXWHvcrRFGy2cizRQE08v%2FNGFX1ECUvkvGEJIJo%3D")
  const [paitha] = useState("https://orderfreshlife.blob.core.windows.net/vegies/tempImageDFU1ev.webp?sp=r&st=2024-03-16T23:13:22Z&se=2025-03-17T07:13:22Z&spr=https&sv=2022-11-02&sr=b&sig=9DhZyNVPpL5t8csCmmsnS2QW2rlUcZkcNQgLj3zBNFU%3D")
  const [tinda] = useState("https://orderfreshlife.blob.core.windows.net/vegies/tinda.webp?sp=r&st=2024-03-16T23:13:42Z&se=2025-03-17T07:13:42Z&spr=https&sv=2022-11-02&sr=b&sig=xe02HJOpLo7GpKyxkthmz%2F1eLqlCOH1426vCpk073Uw%3D")
  const [tomato] = useState("https://orderfreshlife.blob.core.windows.net/vegies/tomato.webp?sp=r&st=2024-03-16T23:14:03Z&se=2025-03-17T07:14:03Z&spr=https&sv=2022-11-02&sr=b&sig=vC2N4bqvYx7fqwk5AZZ%2F6oKQK1TcVP4AIhfMWOX4IFk%3D")
  const [tori] = useState("https://orderfreshlife.blob.core.windows.net/vegies/tori.webp?sp=r&st=2024-03-16T23:14:16Z&se=2025-03-17T07:14:16Z&spr=https&sv=2022-11-02&sr=b&sig=un3FRiIfPqwsoTlnZMnY0%2FfRIGfSKqOQR0l%2FZiZ6RY0%3D")
  const [watermelon] = useState("https://orderfreshlife.blob.core.windows.net/vegies/watermelon.webp?sp=r&st=2024-03-16T23:14:28Z&se=2025-03-17T07:14:28Z&spr=https&sv=2022-11-02&sr=b&sig=SdrioEKCYf%2BJ3U2YwLJhT%2FpyWwakCWThfmudA5u8OvI%3D")
  const [yellowcap] = useState("https://orderfreshlife.blob.core.windows.net/vegies/yellow%20shimla.webp?sp=r&st=2024-03-16T23:14:46Z&se=2025-03-17T07:14:46Z&spr=https&sv=2022-11-02&sr=b&sig=23G24nx7TwJe1CtWadLKoauD3fnXqRRfEIl0B5Qb70s%3D")
  const [zuc] = useState("https://orderfreshlife.blob.core.windows.net/vegies/zucchini-green-approx-225-g-300-g-product-images-o590003901-p590041416-0-202203151959.jpg.webp?sp=r&st=2024-03-16T23:15:02Z&se=2025-03-17T07:15:02Z&spr=https&sv=2022-11-02&sr=b&sig=Xw9ROGklOUjCx4Taz12yTJWuQM7cHY8fR9UNJC7PGqM%3D")
 

 
  function openSideBar() {
    const sidebar = document.querySelector(".sidebar");
    sidebar.style.transition = "all 0.5s";

    sidebar.style.left = "0";
    setSideBarStatus((sidebarStatus) => false);
  }

  function closeSideBar() {
    const sidebar = document.querySelector(".sidebar");
    sidebar.style.transition = "all 0.5s";

    sidebar.style.left = "-100%";
    setSideBarStatus((sidebarStatus) => true);
  }
  

  function loggingOut() {
    navigate('/login');
    setlogout(loggingout=> true)
    localStorage.setItem('b2ctoken', 'logout')
  }

  async function otp() {
    const options = {
      method: "POST",
      url: "https://2factor.in/API/V1/ca6e0ae0-e223-11ee-8cbb-0200cd936042/SMS/+919478181139/AUTOGEN/OTP1",

      data: {
        Status: "Success",
        Details: "l60gaseavbemjvlgw7o-7303503698702e11",
        OTP: "565059",
      },
    };

    const response = await axios.request(options);
    console.log(response);
  }

  // async function vid(){
  //   const video = document.getElementById('video')
  //   const stream=await navigator.mediaDevices.getUserMedia({video:true});
  //   video.srcObject=stream
  // }

  

  useEffect((el) => {
    axios({
      method: "POST",
      url: "https://wheelb2cback.onrender.com/api/v1/product/all-products",
      data : { 
        token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout'
      }
    }).then((res) => {
      if (res.data.status === "success") {
        let fruitsArray = []
        let vegetableArray = []
        let englishArray = []
        let frozenArray = []
        setAllProductsFromBackend(allProductsFromBackend=> res.data.data.items)
        res.data.data.items.forEach(el=>{
          if(el.name==='allo' || el.name==='adrak' || el.name==='lasan' || el.name==='onion' || el.name==='tomato'){
            vegetableArray.push(el)
          }

          if(el.name==='apple' || el.name==='grapes' || el.name==='anar' || el.name==='amrood' || el.name==='mango'){
            fruitsArray.push(el)
          }
        })

        setFruits((fruits) => fruitsArray);
        setVegetables(vegetables=> vegetableArray)
        setExistingCarttoMain((existingCartTomain) => res.data.data.nameArr);
        // setEnglish(english => res.data.data.english)
        // setFrozen(frozen=> res.data.data.frozen)
        // setComboOffer(comboOffer=> res.data.data.combo)
        // setAllStuff(allStuff=> res.data.data.allStuff)
      }
    });
  }, [vegetables, allProductsFromBackend]);

  // useEffect((el) => {
  //   axios({
  //     method: "post",
  //     url: "https://wheelb2cback.onrender.com/api/v1/product/all-products",
  //     data : {
  //       token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout'
  //     }
  //   }).then((res) => {
  //     if (res.data.status === "success") {
  //       setExistingCarttoMain((existingCartTomain) => res.data.data.nameArr);
  //     }
  //   });
  // }, []);

  const [openRegisterationpanel, setRegisterationPanel] = useState(false)
  function openingRegisterationPanel(cond){
    setRegisterationPanel(openRegisterationpanel=> false)
  }

  function addToCart(el) {
    const itemName = el.target.parentNode.children[1].textContent
    let units = 0;
    if(itemName === 'kiwi' || itemName === 'avacado' || itemName === 'banana' || itemName === 'dragon' || itemName === 'pineapple rani' || itemName === 'raja pineapple' || itemName === 'sharda' || itemName === 'papita' || itemName === 'kharbooja' || itemName === 'water melon'){
      units = 1;
    }else if(itemName === 'red capsicum' || itemName === 'yellow capsicum' || itemName === 'allo' || itemName === 'arbi' || itemName === 'amla' || itemName === 'baigan' || itemName === 'bhindi' || itemName === 'beans' || itemName === 'gajar' || itemName === 'goal giya' || itemName === 'hara dhaniya' || itemName === 'hari mirch' || itemName === 'hara payaz' || itemName === 'halwa kaddu' || itemName === 'kheera china' || itemName === 'karela' || itemName === 'lokki' || itemName === 'kheera desi' || itemName === 'mooli' || itemName === 'onion' || itemName === 'patta gobhi' || itemName === 'pudina' || itemName === 'phool gobhi' || itemName === 'palak' || itemName === 'shimla mirch' || itemName === 'zimikhand' || itemName === 'bhe' || itemName === 'turi' || itemName === 'tomato'){
      units = 0.25;
    }else if(itemName === 'nimbu' || itemName === 'basil' || itemName === 'adrak' || itemName === 'lasan' || itemName === 'parsely' || itemName === 'rose merry' || itemName === 'thym'){
      units = 0.10;
    }else if(itemName === 'cherry tomato' || itemName === 'lettuce' || itemName === 'cheery' || itemName === 'baby corn' || itemName === 'celery' || itemName === 'pokchey' || itemName === 'leeks' || itemName === 'mashroom'){
      units = 0.20;
    }else if(itemName === 'beet root' || itemName === 'orange' || itemName === 'mausmi' || itemName === 'mango' || itemName === 'grapes' || itemName === 'babbu gosha' || itemName === 'amrood' || itemName === 'anar' || itemName === 'apple' || itemName === 'red cabbage' || itemName === 'peeled garlic' || itemName === 'khatal' || itemName === 'broccoli' || itemName === 'frozen mattar' || itemName === 'china cabbage' || itemName === 'ice berg' || itemName === 'zucchini green' || itemName === 'zucchini yellow'){
      units = 0.50;
    }else if(itemName === 'half boiled noodles' || itemName === 'french fries' || itemName === 'masala fries' ||  itemName === 'soya chaap' || itemName === 'sweet corn' || itemName === 'frozen vegetables'){
      units = 1;
    }
    axios({
      method: "POST",
      url: "https://wheelb2cback.onrender.com/api/v1/user/add-to-cart",
      data: {
        itemName: el.target.parentNode.children[1].textContent,
        price: el.target.parentNode.children[2].textContent.split("/-")[0],
        units: units,
        token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout'
      },
    }).then((res) => {
      if (res.data.status === "success") {
        Swal.fire({
          title: "Success!",
          text: res.data.data.message,
          icon: "Success",
          confirmButtonText: "close",
        });
        setExistingCarttoMain((existingCartTomain) => res.data.data.nameArr);
      }else{
        setRegisterationPanel(openRegisterationpanel=> true)
      }
    })
  }

  function add(el){
    const val = el.target.parentNode.children[1];
    const name = el.target.parentNode.parentNode.children[1].textContent
    if(name === 'kiwi' || name === 'avacado' || name === 'banana' || name === 'dragon' || name === 'pineapple rani' || name === 'raja pineapple' || name === 'sharda' || name === 'papita' || name === 'kharbooja' || name === 'water melon'){
      val.textContent = (Number(val.textContent) + 1).toFixed(1);
    }else if(name === 'red capsicum' || name === 'yellow capsicum' || name === 'allo' || name === 'arbi' || name === 'amla' || name === 'baigan' || name === 'bhindi' || name === 'beans' || name === 'gajar' || name === 'goal giya' || name === 'hara dhaniya' || name === 'hari mirch' || name === 'hara payaz' || name === 'halwa kaddu' || name === 'kheera china' || name === 'karela' || name === 'lokki' || name === 'kheera desi' || name === 'mooli' || name === 'onion' || name === 'patta gobhi' || name === 'pudina' || name === 'phool gobhi' || name === 'palak' || name === 'shimla mirch' || name === 'zimikhand' || name === 'bhe' || name === 'turi' || name === 'tomato'){
      val.textContent = (Number(val.textContent) + 0.25).toFixed(1);
    }else if(name === 'nimbu' || name === 'adrak' || name === 'lasan' || name === 'basil' || name === 'parsely' || name === 'rose merry' || name === 'thym'){
      val.textContent = (Number(val.textContent) + 0.10).toFixed(1);
    }else if(name === 'cherry tomato' || name === 'lettuce' || name === 'cheery' || name === 'baby corn' || name === 'celery' || name === 'pokchey' || name === 'leeks' || name === 'leeks' || name === 'mashroom'){
      val.textContent = (Number(val.textContent) + 0.20).toFixed(1);
    }else if(name === 'frozen mattar' || name === 'beet root' || name === 'orange' || name === 'mausmi' || name === 'mango' || name === 'grapes' || name === 'babbu gosha' || name === 'amrood' || name === 'anar' || name === 'apple' || name === 'red cabbage' || name === 'peeled garlic' || name === 'khatal' || name === 'broccoli' || name === 'china cabbage' || name === 'ice berg' || name === 'zucchini green' || name === 'zucchini yellow'){
      val.textContent = (Number(val.textContent) + 0.50).toFixed(1);
    }else if(name === 'half boiled noodles' || name === 'french fries' || name === 'masala fries' ||  name === 'soya chaap' || name === 'sweet corn' || name === 'frozen vegetables'){
      val.textContent = (Number(val.textContent) + 1).toFixed(1);
    }
    axios({
      method: "POST",
      url: "https://wheelb2cback.onrender.com/api/v1/user/cart-update",
      data: {
        name: name,
        units: val.textContent,
        token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout'
      },
    }).then((res) => {
      if (res.data.status === "success") {
        
        setAllProductsFromBackend(allProductsFromBackend=> res.data.data.items)

      }else{
        setRegisterationPanel(openRegisterationpanel=> true)
      }
    })

  }

  function substract(el){
    const val = el.target.parentNode.children[1];
    const name = el.target.parentNode.parentNode.children[1].textContent
    if(name === 'kiwi' || name === 'avacado' || name === 'banana' || name === 'dragon' || name === 'pineapple rani' || name === 'raja pineapple' || name === 'sharda' || name === 'papita' || name === 'kharbooja' || name === 'water melon'){
      val.textContent = (Number(val.textContent) - 1).toFixed(1);
    }else if(name === 'red capsicum' || name === 'yellow capsicum' || name === 'allo' || name === 'arbi' || name === 'amla' || name === 'baigan' || name === 'bhindi' || name === 'beans' || name === 'gajar' || name === 'goal giya' || name === 'hara dhaniya' || name === 'hari mirch' || name === 'hara payaz' || name === 'halwa kaddu' || name === 'kheera china' || name === 'karela' || name === 'lokki' || name === 'kheera desi' || name === 'mooli' || name === 'onion' || name === 'patta gobhi' || name === 'pudina' || name === 'phool gobhi' || name === 'palak' || name === 'shimla mirch' || name === 'zimikhand' || name === 'bhe' || name === 'turi' || name === 'tomato'){
      val.textContent = (Number(val.textContent) - 0.25).toFixed(1);
    }else if(name === 'nimbu' || name === 'adrak' || name === 'lasan' || name === 'basil' || name === 'parsely' || name === 'rose merry' || name === 'thym'){
      val.textContent = (Number(val.textContent) - 0.10).toFixed(1);
    }else if(name === 'cherry tomato' || name === 'lettuce' || name === 'cheery' || name === 'baby corn' || name === 'celery' || name === 'pokchey' || name === 'leeks' || name === 'leeks' || name === 'mashroom'){
      val.textContent = (Number(val.textContent) - 0.20).toFixed(1);
    }else if(name === 'frozen mattar' || name === 'beet root' || name === 'orange' || name === 'mausmi' || name === 'mango' || name === 'grapes' || name === 'babbu gosha' || name === 'amrood' || name === 'anar' || name === 'apple' || name === 'red cabbage' || name === 'peeled garlic' || name === 'khatal' || name === 'broccoli' || name === 'china cabbage' || name === 'ice berg' || name === 'zucchini green' || name === 'zucchini yellow'){
      val.textContent = (Number(val.textContent) - 0.50).toFixed(1);
    }else if(name === 'half boiled noodles' || name === 'french fries' || name === 'masala fries' ||  name === 'soya chaap' || name === 'sweet corn' || name === 'frozen vegetables'){
      val.textContent = (Number(val.textContent) - 1).toFixed(1);
    }
    axios({
      method: "POST",
      url: "https://wheelb2cback.onrender.com/api/v1/user/cart-update",
      data: {
        name: name,
        units: val.textContent,
        token : localStorage.getItem('b2ctoken') ? localStorage.getItem('b2ctoken') : 'logout'
      },
    }).then((res) => {
      if (res.data.status === "success") {
        setAllProductsFromBackend(allProductsFromBackend=> res.data.data.items)

      }else{
        setRegisterationPanel(openRegisterationpanel=> true)
      }
    })

  }



  function addingComboOfferToCart(el){
    el.preventDefault()
    const listArr = [...el.target.parentNode.parentNode.children]
    const quantity = el.target.parentNode.children[0]
    const count = listArr.length
    let runningCount = 0
    const finalist = []
    listArr.forEach(el=>{
        if(runningCount === count-1){
            return
        }
        runningCount++
        const obj = {
            name : el.textContent.split('|')[0],
            quantity : el.textContent.split('|')[1]!=='' ? el.textContent.split('|')[1].split('gm')[0]/1000 : Number(quantity.value) 
        }
        finalist.push(obj)
    })
    axios({
        method : 'POST',
        url : 'https://wheelb2cback.onrender.com/api/v1/user/combo-offer',
        data : {
            list : finalist
        }
    })
  }

  function exploreToFruits(){
    props.filterPassing('fruits')
    navigate('/all-products')
  }
  function exploreToVege(){
    props.filterPassing('vegetables')
    navigate('/all-products')
  }
  function exploreToEng(){
    props.filterPassing('english')
    navigate('/all-products')
  }
  function exploreToFrozen(){
    props.filterPassing('frozen')
    navigate('/all-products')
  }

  ///************************searning on top */
  const [showResultSearch, setSearchResult] = useState(false)
  const [filteritems, setfilteritems] = useState([])
  const searchValue = createRef()
  function searching(){
    if(searchValue.current.value===''){
      setSearchResult(showResultSearch=> false)
    }else{
      setSearchResult(showResultSearch=> true)
      const filter_name = searchValue.current.value;
      const newArr = allProductsFromBackend.filter((el)=>{
        if(el.name.includes(filter_name.toLowerCase())){
          return el
        }
      })
      setfilteritems(filteritems=> newArr)
      console.log(filteritems)
    }
  }

  function navigatingtoALlPRoductPageWithSearchedItem(el){
    el.preventDefault()
    props.searchingMainPageResultToallProducts(el.target.parentNode.children[0].textContent)
    navigate('/all-products')
  }

  

  return (
    <div className="main">
      {openRegisterationpanel && 
        <props.AccountRegisteration openingRegisterationPanel={openingRegisterationPanel} />
      }
      <img src={phone} className="icon telephone" alt="phone"/>
      <div className="sidebar  flex flex-dir gap16">
        <h2 className="sidebarMenu">Additional facalities</h2>
        <div className="flex flex-dir ">
          {/* <Link to="/open-acc" className="sidebarBtn">
              &rarr; Create account
          </Link> */}
          {/* <Link to="/login" className="sidebarBtn">
              &rarr; Login to your account
          </Link> */}
          <Link to="/placed" className="sidebarBtn">
            &rarr; Your orders
          </Link>

          <Link to="/refund-orders" className="sidebarBtn">
            &rarr; Refund orders
          </Link>

          <Link to='/history' className='sidebarBtn'>
            &rarr; History
          </Link>

          <Link to="/feedback" className="sidebarBtn">
            &rarr; Feedback
          </Link>

          <Link to="/complain" className="sidebarBtn">
            &rarr; Complain your issue
          </Link>

          <Link to="/contact" className="sidebarBtn">
            &rarr; Contact us
          </Link>

          <Link to="/terms-conditions" className="sidebarBtn">
            &rarr; Terms & conditions
          </Link>

          <Link to="/refund" className="sidebarBtn">
            &rarr; Refund policy
          </Link>

          <a href="https://www.freeprivacypolicy.com/live/a498fbfb-95b4-4680-b2b3-ee9b99453b47" className="sidebarBtn">
            &rarr; Privacy policy
          </a>

          <Link to="/forgot" className="sidebarBtn">
            &rarr; Change your password
          </Link>

          <Link onClick={loggingOut} to="/" className="sidebarBtn">
            &rarr; Logout
          </Link>

          <Link to="/setting" className="sidebarBtn">
            &rarr; Setting
          </Link>


        </div>
      </div>
      <div className="top flex flex-2 gap48 pad16">
        {showResultSearch &&
            <div className="searchpanelResult pad16">
              <div className="searchresult pad16 flex flex-dir gap16">
                <p className="resultitemsCount" >Result items - {filteritems.length}</p>
                <div className="grid grid-1-col gap16">
                  {filteritems.map(el=>
                    <div className="searchitembox pad8 flex flex-2 flex gap8">
                      <p className="searchitemname">{el.name}</p>
                      <p className="searchitemname">{el.price}/- kg</p>
                      <button onClick={navigatingtoALlPRoductPageWithSearchedItem} className="addtocart addbtn">see product</button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          }
        
        <input ref={searchValue} onChange={searching} className="inp inp__updated" placeholder="search*" type="text"/>
        {sidebarStatus ? (
          <img
            onClick={openSideBar}
            src={Menu}
            className="icon icon__ sidebarImgAsBtn"
            alt="icon"
          />
        ) : (
          <img
            onClick={closeSideBar}
            src={Close}
            className="icon icon__ sidebarImgAsBtn"
            alt="icon"
          />
        )}
      </div>
      <div className="middle   gap16">
        
        
        <div className="desc pad8 flex flex-dir flex-1">
          <p className="dess">Order by 11:59pm & get next day delivery in morning</p>
          <p className="dess">Get fresh fruits & vegetable at door step</p>
        </div>
        <div className="promotionbox pad16">
          <h1 className="welcomeToApp">
            Welcome to <br /> <span>&mdash; Order fresh life &mdash;</span>
          </h1>
          <div class="scroll-left">
            <div className="srco flex  gap16">
              <div className="boxxx pad8 flex flex-dir gap16">
                <img src={apple} className="lens" alt="lens" />
                <p className="name displayName">Fresh fruits</p>
              </div>
              <div className="boxxx pad8 flex flex-dir gap16">
                <img src={amla} className="lens" alt="lens" />
                <p className="name displayName">Fresh vegetables</p>
              </div>
              <div className="boxxx pad8 flex flex-dir gap16">
                <img src={rawcado} className="lens" alt="lens" />
                <p className="name displayName">Exostic vegetables</p>
              </div>
              <div className="boxxx pad8 flex flex-dir gap16">
                <img src={kiwi} className="lens" alt="lens" />
                <p className="name displayName">Fresh fruits</p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="slid">
          <Slider>
            {slides.map((slide, index) => <div key={index}>
              <h2>{slide.title}</h2>
              <div>{slide.description}</div>
            </div>)}
          </Slider>
        </div>  */}

        <Link to="/all-products" className="fruitsMove">
          <div className="fruits pad16 flex flex-dir gap8">
            <div className="design_circle"></div>
            <p className="sub">We have fresh fruits</p>
            <p className="explain">
              Our fruits are fresh and dirt free with full premium quality and
              fully system delivery
            </p>
            <div className="contentBox flex flex-1 gap32">
                {fruits.slice(0,4).map(el=>
                    <div className="flex flex-dir flex-1 gap8">
                      {el.name==='apple' &&
                       <img src={apple} className="icon icon__" alt="fruits" />
                      }
                      {el.name==='anar' && <img src={anar} className="icon icon__" alt="fruits" />}
                      {el.name==='amrood' && <img src={amrood} className="icon icon__" alt="fruits" />}
                      {el.name==='grapes' && <img src={grapes} className="icon icon__" alt="fruits" />}
                      <p className="toppum">{el.name}</p>
                    </div>
                )}
            </div>
          </div>
        </Link>

        <Link to="/all-products" className="fruitsMove">
          <div className="vegies fruits pad16 flex flex-dir gap8">
            <div className="design_circle"></div>
            <p className="sub">We have fresh vegeies</p>
            <p className="explain">
              Our fruits are fresh and dirt free with full premium quality and
              fully system delivery
            </p>
            <div className="flex flex-1 gap32">
                {vegetables.slice(0,4).map(el=>
                    <div className="flex flex-dir flex-1 gap8">
                      {el.name==='allo' && <img src={patato} className="icon icon__" alt="fruits" />}
                      {el.name==='adrak' && <img src={adrak} className="icon icon__" alt="fruits" />}
                      {el.name==='lasan' && <img src={lasan} className="icon icon__" alt="fruits" />}
                      {el.name==='onion' && <img src={onion} className="icon icon__" alt="fruits" />}
                      <p className="toppum">{el.name}</p>
                    </div>
                )}
            </div>
          </div>
        </Link>
        <div className="pro">
          <img src={Pro2} alt="pro" className="promotionIm pro2" />
        </div>

        <div className="addProductDirectTocart direct flex flex-dir gap16">
          <p className="special">&mdash; Explore our fruit exibition</p>
          <div className="addtocartbox flex pad8 gap16">
            {fruits.map((el) => (
              <div
                className={
                  existingCartTomain.includes(el.name)
                    ? "productbox pad16 flex flex-1 flex-dir gap8 activated"
                    : "productbox pad16 flex flex-1 flex-dir gap8"
                }
              >
                {el.price===0 &&
                  <div className="outOffStock flex flex-1">
                    <p className="name outStock">out of stock</p>
                  </div>
                }             
                {el.name==='apple' && <img src={apple} className="icon" alt="fruits" />}
                {el.name==='anar' && <img src={anar} className="icon" alt="fruits" />}
                {el.name==='amrood' && <img src={amrood} className="icon" alt="fruits" />}
                {el.name==='grapes' && <img src={grapes} className="icon" alt="fruits" />}
                {el.name==='mango' && <img src={mango} className="icon" alt="fruits" />}
                
                <p className="name">{el.name}</p>
                <p className="name">{el.price}/- kg.</p>
                <p className="name">{el.quantity*1000} gram</p>
                {el.price!==0 ?
                  <>
                    {existingCartTomain.includes(el.name) ? (
                      <div className="addorubstract flex flex-1 gap8">
                        <button onClick={substract} className="addcart minusmain">-</button>
                        <p className="unitsmain">{el.quantity}</p>
                        <button onClick={add} className="addcart addmain">+</button>
                        <p className="unitsmain">kg</p>
                      </div>
                    ) : (
                      <button onClick={addToCart} className="addcart">
                        Add+
                      </button>
                    )}
                  </>
                :
                  <button className="addcart">
                    soon...
                  </button>
                }
              </div>
            ))}
          </div>
        </div>

        <div className="addProductDirectTocart direct flex flex-dir gap16">
          <p className="special">&mdash; Explore our vegetable exibition</p>
          <div className="addtocartbox pad8 flex gap16">
            {vegetables.map((el) => (
              <div className={
                existingCartTomain.includes(el.name)
                  ? "productbox productbox pad16 flex flex-1 flex-dir gap8 activated"
                  : "productbox productbox pad16 flex flex-1 flex-dir gap8"
              }>
                {el.price===0 &&
                  <div className="outOffStock flex flex-1">
                    <p className="name outStock">out of stock</p>
                  </div>
                }
                {el.name==='allo' && <img src={patato} className="icon" alt="fruits" />}
                {el.name==='adrak' && <img src={adrak} className="icon" alt="fruits" />}
                {el.name==='lasan' && <img src={lasan} className="icon" alt="fruits" />}
                {el.name==='onion' && <img src={onion} className="icon" alt="fruits" />}
                {el.name==='tomato' && <img src={tomato} className="icon" alt="fruits" />}
                
                <p className="name">{el.name}</p>
                {el.price!==0 ?<p className="name">{el.price}/- kg.</p> : <p className="name">OFS</p>}
                <p className="name">{el.quantity*1000} gram</p>
                
                {el.price!==0 ?
                  <>
                    {existingCartTomain.includes(el.name) ? 
                      <div className="addorubstract flex flex-1 gap8">
                        <button onClick={substract} className="addcart minusmain">-</button>
                        <p className="unitsmain">{el.quantity}</p>
                        <button onClick={add} className="addcart addmain">+</button>
                        <p className="unitsmain">kg</p>
                      </div>
                       : 
                      <button onClick={addToCart} className="addcart">
                        Add+
                      </button>
                    }
                  </>
                : 
                <button className="addcart">
                  soon...
                </button>
                }
              </div>
            ))}
          </div>
        </div>

        <div className="pro">
          <img src={Pro} alt="pro" className="promotionIm" />
        </div>

        <div className="explore_catagories grid grid-2-col gap16 pad16">
          <p className="special catalogTopHead">&mdash; Explore our catagories</p>
          
          <button onClick={exploreToFruits} className="btncatalog" >
            <div className="catalog pad16 flex flex-1 flex-dir gap16">
              <img src={raniPine} className="icon" alt="fruits"/>
              <p className="catalogNames">Fruits</p>
            </div>
          </button>

          <button onClick={exploreToVege} className="btncatalog" >
            <div className="catalog pad16 flex flex-1 flex-dir gap16">
              <img src={AllVeg} className="icon" alt="fruits"/>
              <p className="catalogNames">Vegetables</p>
            </div>
          </button>


          <button onClick={exploreToEng} className="btncatalog" >
            <div className="catalog pad16 flex flex-1 flex-dir gap16">
              <img src={Cado} className="icon" alt="fruits"/>
              <p className="catalogNames">English items</p>
            </div>
          </button>

          <button onClick={exploreToFrozen} className="btncatalog" >
            <div className="catalog pad16 flex flex-1 flex-dir gap16">
              <img src={corn} className="icon" alt="fruits"/>
              <p className="catalogNames">Frozen items</p>
            </div>
          </button>


        </div>
        
      </div>
      
      <div className="bottom">
        <BottomMenu count={existingCartTomain.length} />
      </div>
    </div>
  );
}

export default Main;
